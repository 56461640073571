import {
  IColumn,
  GB,
  Meeting,
  OrderStatus,
  Order,
  GBArray,
  OrderDetail,
  EventWithColor,
  AuthCookie,
  NewEventForm,
  Roles,
  NewLeads,
  OfferPending,
  OfferSent,
  PaymentWaiting,
  MountingPending,
} from "../models/";
import { startCase, random, isArray } from "lodash";
import { generateName } from "./nameGenerator";
import moment from "moment";
import { Calendar } from "@ionic-native/calendar";
import { CalendarEvent } from "../components/Calendar/Calendar";
import { User } from "../features/Login/redux/actions";
import { isPlatform } from "@ionic/react";
import i18next from "i18next";
import { ConnectionStatus, Network } from "@capacitor/network";
import { Storage } from "@capacitor/storage";

interface ICalendar {
  id: number;
  name: string;
}
interface Location {
  lon: number;
  lat: number;
}

export const handleEventToCalendar = async (
  title: string,
  location: Location | string,
  notes: string = "",
  startDate: Date,
  endDate: Date
) => {
  const CALENDAR_NAME = "DemoCalendar";
  // const parsedLocation = `${location.lon}, ${location.lat}`;
  const parsedLocation = "DummyForNow";
  const userCalendars: ICalendar[] = await Calendar.listCalendars()
    .then((calendars) => calendars)
    .catch((err) => err);

  if (userCalendars && !userCalendars.find((c) => c.name === CALENDAR_NAME)) {
    await Calendar.createCalendar(CALENDAR_NAME);
  }
  const callOptions = Calendar.getCalendarOptions();
  callOptions.calendarName = CALENDAR_NAME;
  Calendar.createEventWithOptions(
    title,
    parsedLocation,
    notes,
    startDate,
    endDate,
    callOptions
  )
    .then(() => undefined)
    .catch((err) => err);
};

export const storageRemove = async (key: string) => {
  await Storage.remove({ key })
    .then(() => true)
    .catch(() => false);
};
export const storageSet = async <T>(key: string, value: T) => {
  await Storage.set({
    key,
    value: JSON.stringify(value),
  });
  return true;
};

export const storageGet = async <T>(key: string): Promise<T | undefined> => {
  const { value } = await Storage.get({ key });
  const parsedValue = value && JSON.parse(value);
  const storagedValue: T | undefined = parsedValue
    ? (parsedValue as T)
    : undefined;
  return storagedValue;
};

export const clearCookie = async () => {
  await Storage.clear();
};

export const createHeaders = (headers: Object[]) => {
  //example. key_blabla should be Key Blablabla
  // key-blabla should be Key Blablabla
  // keyBlabla should be Key Blablabla
  const parsedHeaders: IColumn[] = [];
  headers.length !== 0 &&
    Object.keys(headers[0]).forEach((header) => {
      const newHeader: IColumn = {
        key: header,
        header: startCase(header),
      };
      parsedHeaders.push(newHeader);
    });
  return parsedHeaders;
};

// Generator

export const getRandomColor = () => {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const generateDate = (previousDate: Date | null): Date => {
  const date = previousDate
    ? moment(previousDate).add(random(48), "hours").toDate()
    : moment().add(random(48), "hours").toDate();
  return date;
};

const generateMeetings = (
  opID: number,
  name: string,
  size: number,
  orderID?: number
): Meeting[] => {
  const meetings: Meeting[] = [];
  const date = generateDate(null);
  for (let index = 0; index < size; index++) {
    const meeting = {
      operatorID: opID,
      start: date,
      end: generateDate(date),
      title: `${name} event! #${index}`,
      meetingID: "_" + Math.random().toString(36).substr(2, 9),
      orderID: orderID,
      notify: false,
    };
    meetings.push(meeting);
  }
  return meetings;
};
export const generateOperators = (size: number): GB[] => {
  const operators: GB[] = [];
  for (let index = 0; index < size; index++) {
    const name = generateName();
    const meetings = generateMeetings(index, name, random(6));
    const operator: GB = {
      id: index,
      name: name,
      color: getRandomColor(),
      isSelected: index === 0 ? true : false,
      meetings: meetings as Meeting[],
    };
    operators.push(operator);
  }
  return operators;
};

export const generateRandomID = () => {
  return "_" + Math.random().toString(36).substr(2, 9);
};

export const generateRandomOrderNumber = () => {
  return Math.floor(Math.random() * 10000);
};

const cities = [
  "Prague",
  "Brno",
  "Ostrava",
  "Cesky Krumlov",
  "Ceske Budejovice",
  "Pilsen",
  "Olomouc",
  "Liberec",
  "Pardubice",
  "Usti Nad Labem",
  "Hradec Kralove",
  "Havirov",
  "Zlin",
  "Kladno",
  "Most",
  "Karvina",
];
const status: OrderStatus[] = [
  "Offer Pending",
  "Offer Sent",
  "Payment Waiting",
  "Mounting Pending",
  "Meassuring",
];
export const generateLeads = (number: number = 10): Order[] => {
  const generatedLeads: Order[] = [];
  const date = generateDate(null);
  for (let index = 0; index < number; index++) {
    generatedLeads.push({
      id: index,
      order_date: date,
      order_id: generateRandomOrderNumber(),
      date_inserted: generateDate(date),
      status: status[Math.floor(Math.random() * 6)],
      customer: {
        customer_id: generateRandomOrderNumber(),
        address: "XXXXX",
        city: cities[Math.floor(Math.random() * 15)],
        company: generateName(),
        country: "Czech Republic",
        email: "email@email.com",
        customer_name: generateName(),
        phone: 4201111111,
        post_code: 1800600,
      },
    });
  }
  return generatedLeads;
};

export const generateMountingRows = (number: number = 10): Order[] => {
  const generatedLeads: Order[] = [];
  const date = generateDate(null);
  for (let index = 0; index < number; index++) {
    generatedLeads.push({
      id: index,
      order_id: generateRandomOrderNumber(),
      order_date: date,
      date_inserted: generateDate(date),
      status: "Mounting Pending",
      customer: {
        customer_id: generateRandomOrderNumber(),
        address: "XXXXX",
        city: cities[Math.floor(Math.random() * 15)],
        company: generateName(),
        country: "Czech Republic",
        email: "email@email.com",
        customer_name: generateName(),
        phone: 4201111111,
        post_code: 1800600,
      },
    });
  }
  return generatedLeads;
};
export const generateGBManagers = (size: number = 3) => {
  const gbmanagers: GBArray = {};
  let colors = [
    "red",
    "white",
    "yellow",
    "green",
    "purple",
    "blue",
    "pink",
    "black",
    "grey",
    "orange",
  ];
  for (let index = 0; index < size; index++) {
    const gbColor = colors[Math.floor(Math.random() * colors.length)];
    const name = generateName();
    const id = index;
    const gbmanager: GB = {
      name,
      id,
      meetings: generateMeetings(
        id,
        name,
        Math.floor(Math.random() * 5)
      ) as Meeting[],
      color: gbColor,
    };
    gbmanagers[id] = gbmanager;

    colors = colors.filter((c) => c !== gbColor);
  }
  return gbmanagers;
};

export const generateOrderDetail = (
  orderID: number,
  assigned_gb_id: number | null,
  gbName: string | null
): OrderDetail => {
  const orderDetail: OrderDetail = {
    order_products: [],
    customer: {
      customer_id: generateRandomOrderNumber(),
      address: "XXXXX",
      city: cities[Math.floor(Math.random() * 15)],
      company: generateName(),
      country: "Czech Republic",
      email: "email@email.com",
      customer_name: generateName(),
      phone: 4201111111,
      post_code: 1800600,
    },
    address_delivery: {
      id_address: 1,
    },
    meeting:
      assigned_gb_id && gbName
        ? generateMeetings(assigned_gb_id, gbName, 1, orderID)[0]
        : undefined,
    notes: [
      {
        new: false,
        author: { id: 0, name: "generated auto" },
        text: "Testing",
        datetime: new Date(Date.now()),
      },
    ],
    offer: [],
    order_id: orderID,
    id: generateRandomOrderNumber(),
  };
  return orderDetail;
};
export const generateOrders = (size: number = 5): Order[] => {
  const gbmamangers = generateGBManagers(10);
  const orders: Order[] = [];
  const date = generateDate(null);
  const ordersDetails: OrderDetail[] = [];
  for (let index = 0; index < size; index++) {
    const selectedGB = gbmamangers[Math.floor(Math.random() * 10)];
    const assigned_gb_id = Math.random() < 0.5 ? selectedGB.id : undefined;
    const order_id = generateRandomOrderNumber();
    const dummyOrder: Order = {
      id: order_id,
      order_date: date,
      order_id: order_id,
      date_inserted: generateDate(date),
      status: assigned_gb_id
        ? status[Math.floor(Math.random() * 5)]
        : "New Leads",
      assigned_gb_id: assigned_gb_id,
      assigned_mounter_id: undefined,
      customer: {
        customer_id: generateRandomOrderNumber(),
        address: "XXXXX",
        city: cities[Math.floor(Math.random() * 15)],
        company: generateName(),
        country: "Czech Republic",
        email: "email@email.com",
        customer_name: generateName(),
        phone: 4201111111,
        post_code: 1800600,
      },
    };
    if (dummyOrder.assigned_gb_id || dummyOrder.assigned_gb_id === 0) {
      const orderDetailsWithGB = generateOrderDetail(
        order_id,
        selectedGB.id,
        selectedGB.name
      );
      ordersDetails.push(orderDetailsWithGB);
    } else if (!dummyOrder.assigned_gb_id) {
      const orderDetails = generateOrderDetail(order_id, null, null);
      ordersDetails.push(orderDetails);
    }
    orders.push(dummyOrder);
  }
  ordersDetails.forEach((od) => {
    if (od.meeting) {
      const meetings: Meeting[] = gbmamangers[od.meeting.operatorID].meetings;
      meetings.push(od.meeting);
    }
  });
  storageSet<GBArray>("gbs", gbmamangers);
  storageSet<Order[]>("orders", orders);
  storageSet<OrderDetail[]>("ordersDetails", ordersDetails);
  return orders;
};
export const validEvent = (event: NewEventForm) => {
  const { startDate, endDate, operatorID } = event;
  if (
    validDate(startDate, endDate!) &&
    // title.length !== 0 &&
    Number(operatorID)
  ) {
    return true;
  } else return false;
};

export const validDate = (startDate: Date, endDate: Date) => {
  if (
    startDate < moment().toDate() ||
    endDate < moment().toDate() ||
    endDate < startDate ||
    startDate > endDate
  ) {
    // ERROR
    return false;
  }
  return true;
};

export const eventStyleGetter = (
  event: EventWithColor,
  startDate: Date,
  endDate: Date,
  gbList: GBArray,
  orderInfo?: OrderDetail
) => {
  const gb = gbList;
  const index = event.operatorID ? Number(event.operatorID) : 0;
  if (event.suggestedMeeting) {
    return { style: { backgroundColor: "grey", opacity: 0.5 } };
  }
  if (gb[index]) {
    // if this is not true, is because the user is not a gb.. admin or booker
    const orderEvent =
      gb && gb[index].meetings.find((m) => m.meetingID === event.meetingID);
    const color = gb && gb[index].color;
    const opacity = "0.7";
    if (orderEvent?.meetingID === orderInfo?.meeting?.meetingID) {
      return {
        style: {
          backgroundColor: "red",
          opacity: !validDate(startDate, endDate) ? opacity : undefined,
        },
      };
    } else {
      return {
        style: {
          backgroundColor: color,
          opacity: !validDate(startDate, endDate) ? opacity : undefined,
        },
      };
    }
  } else {
    return {
      // this style is the default aka for admins
      style: {
        backgroundColor: "grey",
        opacity: undefined,
      },
    };
  }
};
export const renderEvent = (
  gbList: GBArray,
  extraEvents?: CalendarEvent[],
  meetings?: Meeting[],
  suggestedMeetings?: CalendarEvent[]
) => {
  const allMeetings: CalendarEvent[] = [];
  if (meetings && isArray(meetings)) {
    allMeetings.push(...meetings); //if admin..
  } else {
    const ops = gbList ? Object.values(gbList) : undefined;
    if (ops && ops.length > 0) {
      ops.forEach((o) => {
        if (o && o.isSelected) {
          const operatorEvents: CalendarEvent[] = o.meetings;
          operatorEvents.forEach((e) => {
            e.start = moment(e.start).toDate();
            e.end = moment(e.end).toDate();
          });
          allMeetings.push(...operatorEvents);
        }
      });
    }
  }
  extraEvents && allMeetings.push(...extraEvents);
  suggestedMeetings && allMeetings.push(...suggestedMeetings);
  return allMeetings;
};

export const isToday = (someDate: Date | undefined) => {
  const today = new Date();
  if (someDate) {
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  } else {
    return null;
  }
};

export const renderEventOfTheDay = (meetings: Meeting[]) => {
  const allMeetings: CalendarEvent[] = [];
  const operatorEvents: CalendarEvent[] = meetings.filter((m) =>
    isToday(m.start)
  );
  operatorEvents.length > 0 &&
    operatorEvents.forEach((e) => {
      e.start = moment(e.start).toDate();
      e.end = moment(e.end).toDate();
    });
  allMeetings.push(...operatorEvents);
  return allMeetings;
};

export const updateOrderStatus = (
  status: OrderStatus
): { nextStatus: OrderStatus | undefined } => {
  switch (status) {
    case "New Leads":
      return { nextStatus: "Offer Pending" };
    case "Offer Pending":
      return { nextStatus: "Offer Sent" };
    case "Offer Sent":
      return { nextStatus: "Payment Waiting" };
    case "Payment Waiting":
      return { nextStatus: "Mounting Pending" };
    case "Meassuring":
      return { nextStatus: "Finished" };
    default:
      return { nextStatus: undefined };
  }
};

export async function getUserFromStorage(): Promise<User | null> {
  //check if user is stored
  const storedAuth = await storageGet<AuthCookie>("auth");
  if (storedAuth) {
    // can do more validations
    const storedUser: User = {
      userID: storedAuth.auth.userID,
      name: storedAuth.auth.name,
      role: storedAuth.auth.role,
      token: storedAuth.auth.token,
      email: storedAuth.auth.email,
    };
    return storedUser;
  }
  // not in storage so do API call
  return null;
}

export const getRole = (roleID: number): Roles | null => {
  // -2 = super admin
  // -1 = admin
  // 1 = Fitter
  // 2 = Mounter
  // 3 = Booker
  switch (roleID) {
    case -1:
      return "Admin";
    case 1:
      return "GBManager";
    case 2:
      return "Mounter";
    case 3:
      return "Booker";
    default:
      return null;
  }
};

export const fullName = (firstName: string, lastName: string) => {
  return firstName + " " + lastName;
};

export const fullAddress = (address1: string, address2: string) => {
  if (address1 && address1?.trim().length !== 0) {
    return address1 + " " + address2;
  }
  if (address2 && address2?.trim().length !== 0) {
    return address1 + " " + address2;
  }
  return "No Address!";
};

export const dateDiffInDays = (a: Date, b: Date) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

export const isOnline = async (): Promise<boolean> => {
  if (isPlatform("cordova")) {
    return await Network.getStatus().then(
      (status: ConnectionStatus) => status.connected
    );
  }

  return new Promise(() => true); //desktop doesn't have offline mode... ate least for now
};

export const datesAreOnSameDay = (first: Date, second: Date) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

export function dateRangeOverlaps(
  a_start: Date,
  a_end: Date,
  b_start: Date,
  b_end: Date
) {
  if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
  if (a_start <= b_end && b_end <= a_end) return true; // b ends in a
  if (b_start < a_start && a_end < b_end) return true; // a in b
  return false;
}
export function toNewLeads(leads: any[]): NewLeads[] {
  const t = i18next.t.bind(i18next);

  return leads
    .filter((r) => Number(r.current_state_gardinbus) === 1)
    .map((lead) => {
      const formattedLead: NewLeads = {
        gbID: lead.fitter.id_fitter,
        background_color: lead.background_color,
        notes: lead.notes,
        Note: lead.has_note,
        OrderID: Number(lead.id_order),
        OrderDate: isPlatform("cordova")
          ? moment(lead.date_add).format("YYYY-MM-DD ").toString()
          : moment(lead.date_add).format("YYYY-MM-DD hh:mm").toString(),
        CustomerName: fullName(lead.customer.firstname, lead.customer.lastname),
        CustomerPhone:
          isNaN(Number(lead.customer.phone)) ||
          Number(lead.customer.phone) === 0
            ? (t("NotDefined") as string)
            : Number(lead.customer.phone),
        AssignedGB: lead.fitter
          ? fullName(lead.fitter.first_name!, lead.fitter.last_name!)
          : undefined,
        postcode: lead.address_delivery?.postcode ?? "NoPostCode",
        city: lead.address_delivery?.city ?? "NoCity",
        hasMeeting:
          lead.gardinbus_measuring_date !== "0000-00-00 00:00:00"
            ? true
            : false,
      };
      return formattedLead;
    });
}

export function toMeassuring(leads: any[]): any[] {
  const t = i18next.t.bind(i18next);

  const result = leads
    .filter((r) => Number(r.current_state_gardinbus) === 2)
    .map((lead) => {
      const formattedLead = {
        gbID: lead.fitter.id_fitter,
        notes: lead.notes,
        Note: lead.has_note,
        OrderID: Number(lead.id_order),
        OrderDate: isPlatform("cordova")
          ? moment(lead.date_add).format("YYYY-MM-DD ").toString()
          : moment(lead.date_add).format("YYYY-MM-DD hh:mm").toString(),
        CustomerName: fullName(lead.customer.firstname, lead.customer.lastname),
        CustomerPhone:
          isNaN(Number(lead.customer.phone)) ||
          Number(lead.customer.phone) === 0
            ? (t("NotDefined") as string)
            : Number(lead.customer.phone),
        AssignedGB: lead.fitter
          ? fullName(lead.fitter.first_name!, lead.fitter.last_name!)
          : undefined,
        MeasuringDatetime: moment(lead.gardinbus_measuring_date).isValid()
          ? lead.gardinbus_measuring_date!
          : (t("NotDefined") as string),
        postcode: lead.address_delivery?.postcode ?? "NoPostCode",
        city: lead.address_delivery?.city ?? "NoCity",
        hasMeeting:
          lead.gardinbus_measuring_date !== "0000-00-00 00:00:00"
            ? true
            : false,
      };
      return formattedLead;
    });
  return result;
}

export function toOfferPending(leads: any[]): OfferPending[] {
  const t = i18next.t.bind(i18next);

  return leads
    .filter((r) => Number(r.current_state_gardinbus) === 3)
    .map((lead) => {
      const timeWaiting = lead.date_offer_pending_since
        ? dateDiffInDays(
            moment(lead.date_offer_pending_since).toDate(),
            new Date(Date.now())
          )
        : 0;
      const formattedLead: OfferPending = {
        gbID: lead.fitter.id_fitter,
        background_color: lead.background_color,
        notes: lead.notes,
        Note: lead.has_note,
        OrderID: Number(lead.id_order),
        OrderDate: isPlatform("cordova")
          ? moment(lead.date_add).format("YYYY-MM-DD ").toString()
          : moment(lead.date_add).format("YYYY-MM-DD hh:mm").toString(),
        CustomerName: fullName(lead.customer.firstname, lead.customer.lastname),
        CustomerPhone:
          isNaN(Number(lead.customer.phone)) ||
          Number(lead.customer.phone) === 0
            ? (t("NotDefined") as string)
            : Number(lead.customer.phone),
        AssignedGB: lead.fitter
          ? fullName(lead.fitter.first_name!, lead.fitter.last_name!)
          : undefined,
        TimePendingInDays: timeWaiting,
        postcode: lead.address_delivery?.postcode ?? "NoPostCode",
        city: lead.address_delivery?.city ?? "NoCity",
        hasMeeting:
          lead.gardinbus_measuring_date !== "0000-00-00 00:00:00"
            ? true
            : false,
        warningLevel:
          timeWaiting > 5 && timeWaiting <= 10
            ? "warning"
            : timeWaiting > 10
            ? "danger"
            : undefined,
      };
      return formattedLead;
    });
}

export function toOfferSent(leads: any[]): OfferSent[] {
  const t = i18next.t.bind(i18next);

  return leads
    .filter((r) => Number(r.current_state_gardinbus) === 4)
    .map((lead) => {
      const timeSent = lead.date_offer_sent
        ? dateDiffInDays(
            moment(lead.date_offer_sent).toDate(),
            new Date(Date.now())
          )
        : 0;
      const formattedLead: OfferSent = {
        gbID: lead.fitter.id_fitter,
        background_color: lead.background_color,
        notes: lead.notes,
        Note: lead.has_note,
        OrderID: Number(lead.id_order),
        OrderDate: isPlatform("cordova")
          ? moment(lead.date_add).format("YYYY-MM-DD ").toString()
          : moment(lead.date_add).format("YYYY-MM-DD hh:mm").toString(),
        CustomerName: fullName(lead.customer.firstname, lead.customer.lastname),
        CustomerPhone:
          isNaN(Number(lead.customer.phone)) ||
          Number(lead.customer.phone) === 0
            ? (t("NotDefined") as string)
            : Number(lead.customer.phone),
        AssignedGB: lead.fitter
          ? fullName(lead.fitter.first_name!, lead.fitter.last_name!)
          : undefined,
        TimeSentInDays: timeSent,
        postcode: lead.address_delivery?.postcode ?? "NoPostCode",
        city: lead.address_delivery?.city ?? "NoCity",
        warningLevel:
          timeSent > 5 && timeSent <= 10
            ? "warning"
            : timeSent > 10
            ? "danger"
            : undefined,
      };
      return formattedLead;
    });
}

export function toPaymentWaiting(leads: any[]): PaymentWaiting[] {
  const t = i18next.t.bind(i18next);

  return leads
    .filter((r) => Number(r.current_state_gardinbus) === 5)
    .map((lead) => {
      const timeWaiting = lead.date_payment_waiting_since
        ? dateDiffInDays(
            moment(lead.date_payment_waiting_since).toDate(),
            new Date(Date.now())
          )
        : 0;
      const formattedLead: PaymentWaiting = {
        gbID: lead.fitter.id_fitter,
        background_color: lead.background_color,
        notes: lead.notes,
        Note: lead.has_note,
        OrderID: Number(lead.id_order),
        OrderDate: isPlatform("cordova")
          ? moment(lead.date_add).format("YYYY-MM-DD ").toString()
          : moment(lead.date_add).format("YYYY-MM-DD hh:mm").toString(),
        CustomerName: fullName(lead.customer.firstname, lead.customer.lastname),
        CustomerPhone:
          isNaN(Number(lead.customer.phone)) ||
          Number(lead.customer.phone) === 0
            ? (t("NotDefined") as string)
            : Number(lead.customer.phone),
        AssignedGB: lead.fitter
          ? fullName(lead.fitter.first_name!, lead.fitter.last_name!)
          : undefined,
        TimeWaitingInDays: timeWaiting,
        postcode: lead.address_delivery?.postcode ?? "NoPostCode",
        city: lead.address_delivery?.city ?? "NoCity",
        warningLevel:
          timeWaiting > 5 && timeWaiting <= 10
            ? "warning"
            : timeWaiting > 10
            ? "danger"
            : undefined,
      };
      return formattedLead;
    });
}

export function toFinished(leads: any[]): any[] {
  const t = i18next.t.bind(i18next);

  return leads
    .filter((r) => Number(r.current_state_gardinbus) === 9)
    .map((lead) => {
      // const timeWaiting = lead.date_payment_waiting_since
      //   ? dateDiffInDays(
      //       moment(lead.date_payment_waiting_since).toDate(),
      //       new Date(Date.now())
      //     )
      //   : 0;
      const formattedLead: any = {
        gbID: lead.fitter.id_fitter,
        background_color: lead.background_color,
        notes: lead.notes,
        Note: lead.has_note,
        OrderID: Number(lead.id_order),
        OrderDate: isPlatform("cordova")
          ? moment(lead.date_add).format("YYYY-MM-DD ").toString()
          : moment(lead.date_add).format("YYYY-MM-DD hh:mm").toString(),
        CustomerName: fullName(lead.customer.firstname, lead.customer.lastname),
        CustomerPhone:
          isNaN(Number(lead.customer.phone)) ||
          Number(lead.customer.phone) === 0
            ? (t("NotDefined") as string)
            : Number(lead.customer.phone),
        AssignedGB: lead.fitter
          ? fullName(lead.fitter.first_name!, lead.fitter.last_name!)
          : undefined,
        postcode: lead.address_delivery?.postcode ?? "NoPostCode",
        city: lead.address_delivery?.city ?? "NoCity",
      };
      return formattedLead;
    });
}

export function toMountingPending(leads: any[]): MountingPending[] {
  const t = i18next.t.bind(i18next);

  return leads
    .filter((r) => Number(r.current_state_gardinbus) === 7)
    .map((lead) => {
      const formattedLead: MountingPending = {
        mounterID: lead.mounter.id,
        background_color: lead.background_color,
        notes: lead.notes,
        Note: lead.has_note,
        OrderID: Number(lead.id_order),
        OrderDate: isPlatform("cordova")
          ? moment(lead.date_add).format("YYYY-MM-DD ").toString()
          : moment(lead.date_add).format("YYYY-MM-DD hh:mm").toString(),
        CustomerName: fullName(lead.customer.firstname, lead.customer.lastname),
        CustomerPhone:
          isNaN(Number(lead.customer.phone)) ||
          Number(lead.customer.phone) === 0
            ? (t("NotDefined") as string)
            : Number(lead.customer.phone),
        Mounter: lead.mounter
          ? fullName(lead.mounter.first_name!, lead.mounter.last_name!)
          : (t("None") as string),
        MountingDate: moment(lead.gardinbus_mounting_date).isValid()
          ? lead.gardinbus_mounting_date
          : (t("NotDefined") as string),
        ExpectedDeliveryDate: moment(lead.gardinbus_mounting_date).isValid()
          ? lead.gardinbus_mounting_date!
          : (t("NotDefined") as string),
        postcode: lead.address_delivery?.postcode ?? "NoPostCode",
        city: lead.address_delivery?.city ?? "NoCity",
      };
      return formattedLead;
    });
}

export function toDeliveryPending(leads: any[]): MountingPending[] {
  const t = i18next.t.bind(i18next);

  return leads
    .filter((r) => Number(r.current_state_gardinbus) === 8)
    .map((lead) => {
      const formattedLead: any = {
        mounterID: lead.mounter.id,
        background_color: lead.background_color,
        notes: lead.notes,
        Note: lead.has_note,
        OrderID: Number(lead.id_order),
        OrderDate: isPlatform("cordova")
          ? moment(lead.date_add).format("YYYY-MM-DD ").toString()
          : moment(lead.date_add).format("YYYY-MM-DD hh:mm").toString(),
        CustomerName: fullName(lead.customer.firstname, lead.customer.lastname),
        CustomerPhone:
          isNaN(Number(lead.customer.phone)) ||
          Number(lead.customer.phone) === 0
            ? (t("NotDefined") as string)
            : Number(lead.customer.phone),
        Mounter:
          lead.mounter.id_mounter !== 0
            ? fullName(lead.mounter.first_name!, lead.mounter.last_name!)
            : (t("None") as string),
        Fitter: lead.fitter
          ? fullName(lead.fitter.first_name!, lead.fitter.last_name!)
          : (t("None") as string),
        postcode: lead.address_delivery?.postcode ?? "NoPostCode",
        city: lead.address_delivery?.city ?? "NoCity",
      };
      return formattedLead;
    });
}

export const isWeekday = (dateString: string) => {
  const date = new Date(dateString);
  const utcDay = date.getUTCDay();

  /**
   * Date will be enabled if it is not
   * Sunday or Saturday
   */
  return utcDay !== 0 && utcDay !== 6;
};
