import React, { useState, useEffect, useRef } from "react";
import { CalendarComponent, MeetingForm, Modal } from "../../components/";
import { RefresherEventDetail } from "@ionic/core";
import { add, save, chatbubbleEllipsesOutline, chevronDownCircleOutline } from "ionicons/icons";
import { IonFab, IonFabButton, IonIcon, IonButton, IonItem, IonLabel, IonRefresher, IonRefresherContent } from "@ionic/react";
import { GB, Meeting, ModalAction, NewEventForm, SlotInfoEvent } from "../../models/";
import { Event as CalendarEvent } from "react-big-calendar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "typesafe-actions";
import { addNewEvent, checkDrivingTime, deleteEvent, editEvent, filterCalendars, initCalendars } from "./redux/actions";
import { dateRangeOverlaps, validDate, validEvent, storageGet, fullName } from "../../helpers";
import { SaveForm } from "../../components/MeetingForm/MeetingForm";
import { isNil } from "lodash";
import { RouteComponentProps } from "react-router";
import { showNotification } from "../Notification/redux/actions";
import { getAPILeadsByTimestamp, getAPILeads } from "../Leads/redux/actions";
import { useTranslation } from "react-i18next";
import moment from "moment";
import SwipableContainer from "../../components/SwipableContainer";
import { addNewOrderNote, initDetails } from "../Details/redux/actions";
const CalendarsFeature: React.FC<RouteComponentProps> = props => {
  const {
    history
  } = props;
  const {
    t
  } = useTranslation();
  const operator = useSelector((state: RootState) => state.calendarsReducer.operator);
  const auth = useSelector((state: RootState) => state.authReducer);
  const orderList = useSelector((state: RootState) => state.leadsReducer.leads);
  const calendarName = useSelector((state: RootState) => state.settingsReducer.calendarChoosen);
  const isLoading = useSelector((state: RootState) => state.calendarsReducer.isLoading);
  const leads = useSelector((state: RootState) => state.leadsReducer.leads);
  const isLoadingDrivingTime = useSelector((state: RootState) => state.calendarsReducer.isLoadingDrivingTime);
  const dateStart = useSelector((state: RootState) => state.calendarsReducer.dateStart);
  const dateEnd = useSelector((state: RootState) => state.calendarsReducer.dateEnd);
  const orderDetail = useSelector((state: RootState) => state.detailsReducer.order_info);
  const dispatch = useDispatch();
  const handleSelectedFilter = (val: number) => {
    dispatch(filterCalendars(val));
  };
  const [modalState, setModal] = useState<{
    event: CalendarEvent | null;
    state: boolean;
  }>({
    event: null,
    state: false
  });
  const [assignedGB] = useState<GB>();
  const [NotesModal, setNotesModal] = useState<{
    bol: boolean;
    notes: {
      cfirstname: string | null;
      clastname: string | null;
      date_add: string | null;
      efirstname: string | null;
      elastname: string | null;
      id_customer: number;
      id_employee: number;
      id_message: number;
      message: string;
      private: number;
    }[];
  }>({
    bol: false,
    notes: []
  });
  const [View, setView] = useState<"month" | "work_week">("work_week");
  const modalAction: ModalAction = {
    name: t("Save"),
    icon: save,
    fn: () => handleDispatchNewEvent(formRef.current!.onSave().newEvent),
    type: "button",
    loading: isLoadingDrivingTime
  };
  useEffect(() => {
    const {
      userID,
      token,
      role,
      name
    } = auth;
    if (userID && token && role && name) {
      storageGet<Date>("timeStamp").then(timeStamp => {
        if (timeStamp && leads.length > 0) {
          return dispatch(getAPILeadsByTimestamp.request({
            user: {
              userID,
              role,
              token,
              name
            },
            timeStamp: timeStamp,
            force: false
          }));
        } else {
          return dispatch(getAPILeads.request({
            user: {
              userID,
              role,
              token,
              name
            },
            force: false
          }));
        }
      }).catch(err => {
        console.error(err);
      });
    }
    dispatch(initCalendars.request({
      userID: Number(auth.userID!),
      userRole: auth.role!,
      calendarName: calendarName ? calendarName : "UNIG",
      token: auth.token!,
      dateStart: dateStart,
      dateEnd: dateEnd
    }));
  }, [auth, calendarName, dateEnd, dateStart, dispatch, leads.length]);
  const formRef = useRef<SaveForm>();
  const handleDispatchNewEvent = (newEvent: NewEventForm) => {
    const event: Meeting = {
      operatorID: Number(newEvent.operatorID),
      start: newEvent.startDate,
      end: newEvent.endDate,
      title: newEvent.title,
      meetingID: newEvent.meetingID,
      orderID: Number(newEvent.orderID),
      calendar: calendarName ? calendarName : "UNIG",
      notify: Boolean(newEvent.notifyCustomer),
      note: newEvent.note
    };
    if (validEvent(newEvent)) {
      const orderDetail = orderList.find(o => o.id_order === Number(event.orderID));
      dispatch(addNewEvent.request({
        token: auth.token!,
        operatorId: Number(newEvent.operatorID),
        event: event,
        id_address: Number(orderDetail?.address_delivery.id_address),
        postcode: Number(orderDetail?.address_delivery.postcode),
        fullName: orderDetail ? fullName(orderDetail?.customer.firstname, orderDetail?.customer.lastname) : ""
      }));
      if (newEvent.note?.length > 0) {
        dispatch(addNewOrderNote.request({
          note: {
            author: {
              name: auth.name,
              id: auth.userID
            },
            datetime: new Date(),
            new: true,
            text: newEvent.note
          },
          token: auth.token!,
          order_id: newEvent.orderID
        }));
      }
      setModal({
        state: false,
        event: null
      });
      setDragEvent({
        startDate: undefined,
        endDate: undefined
      });
    } else {
      dispatch(showNotification({
        message: t("ErrorAddEvent"),
        color: "danger",
        type: "Toast"
      }));
    }
  };
  const [dragEvent, setDragEvent] = useState<{
    startDate: Date | string | undefined;
    endDate: Date | string | undefined;
  }>();
  const renderModal = modalState.state ? <Modal isOpen={modalState.state} onClose={() => {
    setModal({
      event: null,
      state: false
    });
  }} actions={[modalAction]} title={modalState.event?.title ? modalState.event.title : "New Event"}>
      <MeetingForm onChangeOrderId={id => {
      dispatch(initDetails.request({
        id: id,
        token: auth.token!
      }));
    }} orderList={orderList} startDate={dragEvent?.startDate} endDate={dragEvent?.endDate} ref={formRef} gbList={operator} hideGB={assignedGB ? true : false} orderInfo={orderDetail} canCheckDrivingTime={(bol, gbID, order_id, startTime, endTime) => {
      const overlappingMeeting = operator[gbID].meetings.find(meeting => {
        return dateRangeOverlaps(moment(meeting.start!).toDate(), moment(meeting.end!).toDate(), moment(startTime).toDate(), moment(endTime).toDate()) ? meeting : null;
      });
      isNil(overlappingMeeting) ? dispatch(checkDrivingTime.request({
        token: auth.token!,
        meetings: operator[gbID] ? operator[gbID].meetings : [],
        new_order_id: order_id,
        startTime: moment(startTime).toDate(),
        endTime: moment(endTime).toDate()
      })) : dispatch(showNotification({
        color: "warning",
        message: "Warning: This meeting overlaps with another.",
        type: "Toast"
      }));
    }} />
    </Modal> : null;
  const handleEditEvent = (slotInfo: SlotInfoEvent) => {
    const start: Date = new Date(slotInfo.start);
    const end: Date = new Date(slotInfo.end);
    if (validDate(start, end)) {
      return dispatch(editEvent.request({
        event: {
          ...slotInfo.event,
          calendar: calendarName ? calendarName : "UNIG"
        },
        newStartDate: start,
        newEndDate: end,
        token: auth.token!,
        id_user: Number(slotInfo.event.operatorID),
        id_address: Number(orderList.find(o => o.id_order === Number(slotInfo.event.orderID))?.address_delivery.id_address)
      }));
    }
  };
  const renderFab = <IonFab vertical="bottom" horizontal="end" slot="fixed">
      <IonFabButton onClick={() => setModal({
      state: true,
      event: null
    })}>
        <IonIcon icon={add} />
      </IonFabButton>
    </IonFab>;
  const CustomEvent = (event: any) => {
    const eventMeeting: Meeting = event.event;
    return <div style={{
      display: "flex",
      alignContent: "center",
      justifyContent: "space-between",
      height: "100%"
    }}>
        <>
          {event.title}
          {eventMeeting.notes && eventMeeting.notes?.length > 0 && <IonButton className={"goToMapButton"} fill={"clear"} style={{
          right: "0",
          top: "0"
        }} onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setNotesModal({
            bol: true,
            notes: eventMeeting.notes ? eventMeeting.notes : []
          });
        }}>
              <IonIcon icon={chatbubbleEllipsesOutline} />
            </IonButton>}
        </>
      </div>;
  };
  const closeModal: ModalAction = {
    name: "Close",
    fn: () => setNotesModal({
      bol: false,
      notes: []
    }),
    type: "button"
  };
  const renderAssignNotesModal = <Modal title={t("Notes")} isOpen={NotesModal.bol} onClose={() => {
    setNotesModal({
      bol: false,
      notes: []
    });
  }} actions={[closeModal]}>
      {NotesModal.notes.map((note, i) => <>
          <IonItem key={note.message + i}>
            <IonLabel style={{
          paddingLeft: "0.5rem"
        }}>
              <h3>{note.message}</h3>
              <p>{note.date_add}</p>
            </IonLabel>
          </IonItem>
        </>)}
    </Modal>;
  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    dispatch(initCalendars.request({
      userID: Number(auth.userID!),
      userRole: auth.role!,
      calendarName: calendarName ? calendarName : "UNIG",
      token: auth.token!,
      dateStart: dateStart,
      dateEnd: dateEnd
    }));
    !isLoading && event.detail.complete();
  };
  const updateDates = (newStartDate: string | Date, newEndDate: string | Date) => dispatch(initCalendars.request({
    userID: Number(auth.userID!),
    userRole: auth.role!,
    calendarName: calendarName ? calendarName : "UNIG",
    token: auth.token!,
    dateStart: newStartDate,
    dateEnd: newEndDate
  }));
  return <>
      <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
        <IonRefresherContent pullingIcon={chevronDownCircleOutline} pullingText={t("PullToRefresh")} refreshingSpinner="circles" refreshingText={t("Refreshing")}></IonRefresherContent>
      </IonRefresher>
      <div style={{
      height: "100%"
    }}>
        <SwipableContainer handlers={{
        onSwipedDown: () => undefined,
        onSwipedLeft: () => {
          const startOfNextWeek = moment(dateStart).add(1, View === "work_week" ? "weeks" : "month").startOf(View === "work_week" ? "week" : "month").toString();
          const endOfNextWeek = moment(dateEnd).add(1, View === "work_week" ? "weeks" : "month").endOf(View === "work_week" ? "week" : "month").toString();
          updateDates(startOfNextWeek, endOfNextWeek);
        },
        //foward date
        onSwipedRight: () => {
          const startOfPreviousWeek = moment(dateStart).subtract(1, View === "work_week" ? "weeks" : "month").startOf(View === "work_week" ? "week" : "month").toString();
          const endOfPreviousWeek = moment(dateEnd).subtract(1, View === "work_week" ? "weeks" : "month").endOf(View === "work_week" ? "week" : "month").toString();
          updateDates(startOfPreviousWeek, endOfPreviousWeek);
        },
        //back date
        onSwipedUp: () => undefined
      }}>
          <CalendarComponent onView={view => {
          if (view === "month") {
            updateDates(moment().startOf("month").toDate(), moment().endOf("month").toDate());
          }
          setView((view as "month" | "work_week"));
        }} isLoading={Boolean(isLoading)} date={moment(dateStart).toDate()} onNavigate={newDate => {
          if (View === "work_week") {
            const dateEnd = moment(newDate).endOf("week").toString();
            const startDate = moment(newDate).startOf("week").toString();
            updateDates(startDate, dateEnd);
          }
          if (View === "month") {
            const dateEnd = moment(newDate).endOf("month").toString();
            const startDate = moment(newDate).startOf("month").toString();
            updateDates(startDate, dateEnd);
          }
        }} popoverEnabled={true} onError={message => dispatch(showNotification({
          message: message,
          color: "warning",
          type: "Toast"
        }))} onReschedule={(newStart, newEnd, oldMeeting, id_user) => dispatch(editEvent.request({
          newStartDate: newStart,
          newEndDate: newEnd,
          event: oldMeeting,
          token: auth.token!,
          id_user: Number(id_user),
          id_address: Number(orderList.find(o => o.id_order === oldMeeting.orderID)?.address_delivery.id_address)
        }))} onDeleteEvent={meeting => dispatch(deleteEvent.request({
          event: meeting,
          token: auth.token!
        }))} onSeeDetails={meeting => history.push(`/leads/${meeting.orderID}`)} gbs={auth.role! === "GBManager" || auth.role! === "Mounter" ? {
          [Number(auth.userID)]: operator[Number(auth.userID)]
        } : operator} filter={{
          title: auth.role === "GBManager" || auth.role === "Mounter" ? t("MyCalendar") : t("AllCalendars"),
          selectedFilter: id => handleSelectedFilter(parseInt(id)),
          deselectFilter: id => handleSelectedFilter(id)
        }} views={{
          work_week: true,
          month: true
          // day: true,
        }} components={{
          event: CustomEvent
        }} defaultView={"work_week"} view={View} onSelectNewEvent={slotInfo => {
          setDragEvent({
            startDate: slotInfo.start,
            endDate: slotInfo.end
          });
          setModal({
            state: true,
            event: null
          });
        }} onEditEvent={e => handleEditEvent((e as SlotInfoEvent))} />
        </SwipableContainer>
      </div>
      {renderFab}
      {renderModal}
      {renderAssignNotesModal}
    </>;
};
export default CalendarsFeature;