import { IonButton, IonIcon, IonRow, isPlatform, IonFabButton, IonFab, IonList, IonItem, IonLabel, IonInput, IonTextarea } from "@ionic/react";
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { RootState } from "typesafe-actions";
import { ProductCard, Modal, Totals, SpecialConditions } from "../../components";
import { addNewEmptyProduct, cleanProducts, copyProduct, createOffer, deleteProduct, draftOffer, getDraft, getOffers, init, updateOffer, updateProduct, collapseProduct, newExpandProduct } from "./redux/actions";
// import { expandProduct,  } from "../OfferForm/redux/actions";
import { PreFilledOffer } from "../../models";
import { isEqual } from "lodash";
import { addOutline, pricetagOutline } from "ionicons/icons";
import { showNotification } from "../Notification/redux/actions";
import { RouteComponentProps } from "react-router";
import Skeletons from "../../components/Skeletons";
import { useTranslation } from "react-i18next";
import { parsedOfferToBodyRequest } from "./helper";
import { calculateTotalProductsPrice, calculateMeassuringPrice, calculateTotalOrderPrice, calculateMountingPrice, calculateShippingPrice } from "./totalsCalculation";
import "./OfferDetails.css";
import Loading from "../../components/Loading";
import { getMessages } from "../SendSMS/redux/actions";
import useValidateOffers from "./useValidateOffers";
type Props = {
  order_id: string;
  offer_id: string;
};
export interface OfferActions {
  onSend: () => void;
  onSaveDraft: (type: "New" | "Update") => void;
}
const OfferDetailFeature = forwardRef((props: Props & RouteComponentProps, parentRef) => {
  const {
    t
  } = useTranslation();
  // Selector
  const auth = useSelector((state: RootState) => state.authReducer);
  const offers = useSelector((state: RootState) => state.offerDetailReducer.offerDetail?.offers, shallowEqual);
  const extraMounting = useSelector((state: RootState) => state.offerDetailReducer.offerDetail?.extraMounting, isEqual);
  const discount = useSelector((state: RootState) => state.offerDetailReducer.offerDetail?.discount, isEqual);
  const mountingTime = useSelector((state: RootState) => state.offerDetailReducer.offerDetail?.mountingTime, isEqual);
  const generalComment = useSelector((state: RootState) => state.offerDetailReducer.offerDetail?.generalComment, isEqual);
  const isLoading = useSelector((state: RootState) => state.offerDetailReducer.isLoading);
  const redirect = useSelector((state: RootState) => state.offerDetailReducer.redirect);
  const offer_id = useSelector((state: RootState) => state.offerDetailReducer.offer_id, isEqual);
  const configs = useSelector((state: RootState) => state.authReducer.appConfigs);
  const scrollBottom = useSelector((state: RootState) => state.offerDetailReducer.scrollBottom);
  const selectedOfferIndex = useSelector((state: RootState) => state.offerDetailReducer.selectedOfferIndex);
  const commentMessages = useSelector((state: RootState) => state.smsReducer.messages.filter(m => m.type === "comment").map(comment => comment.message));
  const dispatch = useDispatch();
  const [ShowMountingModal, setShowMountingModal] = useState(false);
  const saveDraft = type => {
    const offer = {
      token: auth.token!,
      id_order: Number(props.order_id),
      offer: {
        ...parsedOfferToBodyRequest(auth.appConfigs?.products?.products, offers!),
        cart_extra_discount: ExtraDiscount,
        cart_extra_mounting_price: ExtraMountingPrice,
        estimated_mounting_hours: Number(EstimatedMounting.split(":")[0]),
        estimated_mounting_minutes: Number(EstimatedMounting.split(":")[1]),
        general_comment: GeneralComment ? GeneralComment : "",
        delivery_to_fitter: IsFitterAdrress,
        saveOffer: t("SaveOffer"),
        submitOffer: null
      }
    };
    if (!auth.workingOnline) {
      //im offline
      dispatch(draftOffer(offer));
    } else if (Number(props.order_id) && (Number(props.offer_id) || offer_id) && !offers?.find(o => o.sent) && type === "Update") {
      //already exists so update it
      return dispatch(updateOffer.request({
        ...offer,
        id_offer: offer_id ? offer_id : Number(props.offer_id)
      }));
    } else {
      return dispatch(createOffer.request(offer));
    }
  };
  // onClickFromParent
  useImperativeHandle(parentRef, (): OfferActions => ({
    // send offer to client
    onSend: () => {
      setShowMountingModal(true);
    },
    // save draft
    onSaveDraft: type => saveDraft(type)
  }));

  // State
  const [ExtraMountingPrice, setExtraMountingPrice] = useState(extraMounting ? extraMounting : 0);
  const [ExtraDiscount, setExtraDiscount] = useState(discount ? discount : 0);
  const [EstimatedMounting, setEstimatedMounting] = useState<string>(mountingTime ? mountingTime : "00:00");
  const [IsFitterAdrress, setIsFitterAdrress] = useState(false);
  const [GeneralComment, setGeneralComment] = useState<string>(generalComment ? generalComment : "");
  const [IsNewOffer, setIsNewOffer] = useState(false);
  const {
    data
  } = useValidateOffers(offers ?? [], configs);
  useEffect(() => {
    if (commentMessages.length === 0) {
      dispatch(getMessages.request({
        token: auth.token!
      }));
    }
    if (!offers || offers?.length === 0) {
      dispatch(init());
    }
    discount && setExtraDiscount(discount);
    extraMounting && setExtraMountingPrice(extraMounting);
    mountingTime && setEstimatedMounting(mountingTime);
    generalComment && setGeneralComment(generalComment);
    // dispatch(getDraft.request());
    if (redirect && offer_id === undefined) {
      props.history.goBack();
    }
    if (Number(props.order_id) && Number(props.offer_id) === 0) {
      dispatch(getDraft.request(Number(props.order_id)));
    }
    if (Number(props.order_id) && !isNaN(Number(props.offer_id))) {
      dispatch(getOffers.request({
        id_order: Number(props.order_id),
        id_offer: Number(props.offer_id),
        token: auth.token!
      }));
    }
    if (isPlatform("cordova")) {
      // if it's mobile/ipad
    }
    if (props.offer_id === "new" || !props.offer_id) {
      setIsNewOffer(true);
      addNewEmptyProduct.request({
        discount: ExtraDiscount,
        extraMounting: ExtraMountingPrice,
        generalComment: GeneralComment,
        mountingTime: EstimatedMounting
      });
    }
    return () => {
      setIsNewOffer(false);
      setExtraDiscount(0);
      setEstimatedMounting("00:00");
      setExtraMountingPrice(0);
      setGeneralComment("");
      dispatch(cleanProducts());
    };
    // I want this just to run on component mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    scrollToBottom();
  }, [scrollBottom]);
  useEffect(() => {
    setExtraDiscount(discount ?? 0);
    setExtraMountingPrice(extraMounting ?? 0);
  }, [extraMounting, discount]);

  // Method
  const productPrice = useMemo(() => {
    const totalProductsPrice = offers ? calculateTotalProductsPrice(configs, offers) : 0;
    const orderTotalPrice = offers && calculateTotalOrderPrice(calculateTotalProductsPrice(configs, offers), calculateMeassuringPrice(configs?.global_config, totalProductsPrice), calculateMountingPrice(configs?.attributes, configs?.global_config, configs?.products?.products!, offers), calculateShippingPrice(configs?.global_config, totalProductsPrice, offers));
    const result = orderTotalPrice ? orderTotalPrice + ExtraMountingPrice - ExtraDiscount : 0;
    return result % 1 === 0 ? result : result.toFixed(2);
  }, [offers, configs, ExtraMountingPrice, ExtraDiscount]);
  const buttonRef = useRef<HTMLDivElement | null>(null);
  const renderProducts = useMemo(() => {
    if (offers) {
      return offers.map((_offer, i) => {
        return <ProductCard readyToBeSent={() => {
          // if (ready === false) {
          //   setReady(false);
          // }
        }} commentMessages={commentMessages} loading={isLoading} ordinal_reference={i} onExpand={index => dispatch(newExpandProduct({
          index
        }))} onCollapse={() => dispatch(collapseProduct())} expanded={selectedOfferIndex === i} configs={configs} key={_offer.uuid} offer={_offer} onSave={(newProduct: PreFilledOffer) => {
          dispatch(updateProduct({
            index: i,
            product: newProduct
          }));
          const offer = {
            token: auth.token!,
            id_order: Number(props.order_id),
            offer: {
              ...parsedOfferToBodyRequest(auth.appConfigs?.products?.products, offers!, newProduct, i),
              cart_extra_discount: ExtraDiscount,
              cart_extra_mounting_price: ExtraMountingPrice,
              estimated_mounting_hours: Number(EstimatedMounting.split(":")[0]),
              estimated_mounting_minutes: Number(EstimatedMounting.split(":")[1]),
              general_comment: GeneralComment ? GeneralComment : "",
              delivery_to_fitter: IsFitterAdrress,
              saveOffer: t("SaveOffer"),
              submitOffer: null
            },
            cart_extra_discount: ExtraDiscount,
            cart_extra_mounting_price: ExtraMountingPrice,
            estimated_mounting_hours: Number(EstimatedMounting.split(":")[0]),
            estimated_mounting_minutes: Number(EstimatedMounting.split(":")[1]),
            general_comment: GeneralComment ? GeneralComment : "",
            delivery_to_fitter: IsFitterAdrress,
            saveOffer: t("SaveOffer"),
            submitOffer: null
          };
          if (offer_id || props.offer_id) {
            //existing order

            dispatch(updateOffer.request({
              ...offer,
              id_offer: offer_id ? offer_id : Number(props.offer_id),
              id_order: Number(props.order_id),
              token: auth.token
            }));
          } else if (!props.offer_id && !offer_id) {
            //new order

            dispatch(createOffer.request(offer));
          }

          //saveDraft("Update");
        }} onDelete={() => {
          dispatch(deleteProduct({
            index: i
          }));
        }} onCopy={(productToCopy: PreFilledOffer) => {
          dispatch(copyProduct({
            offer: productToCopy
          }));
          scrollToBottom();
        }} onError={(e: string) => {
          dispatch(showNotification({
            color: "warning",
            message: e,
            type: "Toast"
          }));
        }} order_id={Number(props.order_id)} offer_id={Number(props.offer_id)} />;
      });
    }
  }, [commentMessages, configs, dispatch, isLoading, offers, props.offer_id, props.order_id, selectedOfferIndex]);
  const scrollToBottom = () => {
    buttonRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest"
    });
  };
  const addButton = (key: string) => <IonFab slot="fixed" vertical="bottom" horizontal="end">
        <IonFabButton key={key} style={{
      display: "flex",
      margin: "1.4rem"
    }} onClick={() => {
      dispatch(addNewEmptyProduct.request({
        discount: ExtraDiscount,
        extraMounting: ExtraMountingPrice,
        generalComment: GeneralComment,
        mountingTime: EstimatedMounting
      }));
    }}>
          <IonIcon icon={addOutline} />
        </IonFabButton>
      </IonFab>;
  const [showPriceDetails, setshowPriceDetails] = useState(false);
  const priceDetailsModal = <Modal title="Price Details" isOpen={showPriceDetails} onClose={() => setshowPriceDetails(false)} actions={[{
    name: "Save & Close",
    fn: () => setshowPriceDetails(false),
    type: "button"
  }]}>
        <SpecialConditions key={"special"} EstimatedMounting={EstimatedMounting} ExtraDiscount={ExtraDiscount} IsFitterAdrress={IsFitterAdrress} ExtraMountingPrice={ExtraMountingPrice} GeneralComment={GeneralComment} newGeneralComment={v => setGeneralComment(v)} newEstimatedMounting={v => setEstimatedMounting(v)} newExtraDiscount={v => setExtraDiscount(v)} newExtraMountingPrice={v => setExtraMountingPrice(v)} newIsFitterAdrress={v => setIsFitterAdrress(v)} />
        <Totals key={"totals"} products={offers ? offers : []} extraMountingPrice={ExtraMountingPrice} />
        <IonItem>
          <IonLabel position="floating">Total Price</IonLabel>
          <IonInput readonly value={productPrice} />
        </IonItem>
      </Modal>;
  const mountingModal = <Modal title="Mounting Time" isOpen={ShowMountingModal} onClose={() => setShowMountingModal(false)} actions={[{
    name: "Send",
    fn: () => {
      if (!data.every(element => element === true)) {
        return dispatch(showNotification({
          color: "danger",
          message: "Not all products are ready to be sent!",
          type: "Toast"
        }));
      }
      const offer = {
        token: auth.token!,
        id_order: Number(props.order_id),
        offer: {
          ...parsedOfferToBodyRequest(auth.appConfigs?.products?.products, offers!),
          cart_extra_discount: ExtraDiscount,
          cart_extra_mounting_price: ExtraMountingPrice,
          estimated_mounting_hours: Number(EstimatedMounting.split(":")[0]),
          estimated_mounting_minutes: Number(EstimatedMounting.split(":")[1]),
          general_comment: GeneralComment ? GeneralComment : "",
          delivery_to_fitter: IsFitterAdrress,
          saveOffer: null,
          submitOffer: t("SendOfferToCustomer")
        }
      };
      if (!auth.workingOnline) {
        //im offline
        return dispatch(showNotification({
          color: "danger",
          message: t("SaveAsDraft"),
          type: "Toast"
        }));
      }
      if (offers?.length === 0) {
        return dispatch(showNotification({
          color: "danger",
          message: t("NoProducts"),
          type: "Toast"
        }));
      }
      if (offers?.find(o => !o.readyToSend)) {
        return dispatch(showNotification({
          color: "danger",
          message: t("IncompleteProducts"),
          type: "Toast"
        }));
      } else if (EstimatedMounting === "00:00") {
        return dispatch(showNotification({
          color: "danger",
          message: t("SetEstimatedTime"),
          type: "Toast"
        }));
      } else if (Number(props.order_id) && (Number(props.offer_id) || offer_id)) {
        //already exists so update it
        setShowMountingModal(false);
        return dispatch(updateOffer.request({
          ...offer,
          id_offer: offer_id ? offer_id! : Number(props.offer_id)
        }));
      } else {
        setShowMountingModal(false);
        return dispatch(createOffer.request(offer));
      }
    },
    type: "button"
  }]}>
        <IonList>
          <IonItem>
            <IonLabel position={"floating"}>
              {t("EstimatedMounting")} (hh:mm)
            </IonLabel>
            <IonInput type={"time"} value={EstimatedMounting} onIonChange={e => setEstimatedMounting(e.detail.value!)}></IonInput>
          </IonItem>
          {/* <IonItem>
            <IonLabel>{t("SetFitterAddress")}</IonLabel>
            <IonCheckbox
              checked={IsFitterAdrress}
              onIonChange={(e) => setIsFitterAdrress(e.detail.checked)}
            />
           </IonItem> */}
          <IonItem>
            <IonLabel className="ion-text-wrap" position={"floating"}>
              {t("GeneralComment")}
            </IonLabel>
            <IonTextarea value={GeneralComment} onIonChange={e => setGeneralComment(e.detail.value!)} />
          </IonItem>
        </IonList>
      </Modal>;
  const countProducts = () => {
    let count = 0;
    offers?.forEach(o => {
      count += o.quantity;
    });
    return count;
  };
  return <>
        {priceDetailsModal}
        {mountingModal}
        {!isLoading ? <>
            <IonRow className={"product-header"}>
              <span>
                <h2>
                  {offers && offers.length > 0 ? countProducts() : 0} products
                </h2>
                {!IsNewOffer && <h3 className="total-price">
                    Total:{" "}
                    <b>
                      {productPrice ? productPrice + "kr." : "Error: Check Products"}{" "}
                    </b>
                  </h3>}
              </span>
              <span>
                <IonButton fill="outline" onClick={() => setshowPriceDetails(true)}>
                  <IonIcon icon={pricetagOutline} />
                  Price Details
                </IonButton>
              </span>
            </IonRow>
            {renderProducts}
            {addButton("bottom")}
            <div ref={buttonRef}></div>
          </> : <>
            <Loading loading={isLoading} onGoBack={() => props.history.goBack()} />
            <Skeletons />
          </>}
      </>;
});
export default OfferDetailFeature;