import { createAsyncAction, createAction } from "typesafe-actions";
import {
  Meeting,
  DetailsAPIResponse,
  Customer,
  Note,
  GBArray,
  CustomerResponseOptions,
  Roles,
} from "../../../models/";
import { API_GB } from "./epics";

export const getGBSAction = createAsyncAction(
  "details/GET_GBS_REQUEST",
  "details/GET_GBS_SUCCESS",
  "details/GET_GBS_FAILURE"
)<{ token: string }, API_GB[], Error | string>();

export const initDetails = createAsyncAction(
  "details/INIT_DETAILS_REQUEST",
  "details/INIT_DETAILS_SUCCESS",
  "details/INIT_DETAILS_FAILURE"
)<{ token: string; id: number }, DetailsAPIResponse, Error | string>();

export const getOrderDetailAction = createAsyncAction(
  "details/ORDER_DETAIL_REQUEST",
  "details/ORDER_DETAIL_SUCCESS",
  "details/ORDER_DETAIL_FAILURE"
)<
  { token: string; id_order: number; id_user: number; role: Roles },
  { response: DetailsAPIResponse; user: { id_user: number; token: string } },
  Error | string
>();

export const getEvents = createAsyncAction(
  "details/GET_EVENTS_REQUEST",
  "details/GET_EVENTS_SUCCESS",
  "details/GET_EVENTS_FAILURE"
)<{ token: string; id_user: number }, Meeting[], Error | string>();

export const getAllEvents = createAsyncAction(
  "details/GET_ALL_EVENTS_REQUEST",
  "details/GET_ALL_EVENTS_SUCCESS",
  "details/GET_ALL_EVENTS_FAILURE"
)<
  {
    user: {
      userRole: Roles;
      userID: number;
      token: string;
    };
    calendarName: string;
    dateStart: Date | string;
    dateEnd: Date | string;
  },
  Meeting[],
  Error | string
>();

export const cancelOrder = createAsyncAction(
  "details/CANCEL_ORDER_REQUEST",
  "details/CANCEL_ORDER_SUCCESS",
  "details/CANCEL_ORDER_FAILURE"
)<number, number, Error>();

export const assignToGB = createAsyncAction(
  "details/ASSIGN_GB_REQUEST",
  "details/ASSIGN_GB_SUCCESS",
  "details/ASSIGN_GB_FAILURE"
)<
  { gbID: number; startDate: Date; endDate: Date; title: string },
  {
    gbID: number;
    startDate: Date;
    endDate: Date;
    title: string;
    notify: boolean;
  },
  Error | string
>();

export const reAssignToGB = createAsyncAction(
  "details/RE_ASSIGN_GB_REQUEST",
  "details/RE_ASSIGN_GB_SUCCESS",
  "details/RE_ASSIGN_GB_FAILURE"
)<number, number, Error | string>();
// export const reAssignToGB = createAsyncAction(
//   "details/RE_ASSIGN_GB_REQUEST",
//   "details/RE_ASSIGN_GB_SUCCESS",
//   "details/RE_ASSIGN_GB_FAILURE"
// )<
//   {
//     id_order: number;
//     id_user: number;
//     token: string;
//     startDate: string;
//     endDate: string;
//     orderNote: string;
//     notify: boolean;
//   },
//   {
//     id_order: number;
//     id_user: number;
//     token: string;
//     startDate: string;
//     endDate: string;
//     orderNote: string;
//     notify: boolean;
//   },
//   Error | string
// >();
export const addNewEvent = createAsyncAction(
  "details/NEW_EVENT_REQUEST",
  "details/NEW_EVENT_SUCCESS",
  "details/NEW_EVENT_FAILURE"
)<
  { token: string; operatorId: number; operatorName: string; event: Meeting },
  { operatorId: number; operatorName: string; event: Meeting },
  Error | string
>();

export const editEvent = createAsyncAction(
  "details/EDIT_EVENT_REQUEST",
  "details/EDIT_EVENT_SUCCESS",
  "details/EDIT_EVENT_FAILURE"
)<
  { token: string; newStartDate: Date; newEndDate: Date; event: Meeting },
  { newStartDate: Date; newEndDate: Date; event: Meeting }, //supposedly the api return the new event so newstartdate and end will be just on request
  Error | string
>();

export const deleteEvent = createAsyncAction(
  "details/DELETE_EVENT_REQUEST",
  "details/DELETE_EVENT_SUCCESS",
  "details/DELETE_EVENT_FAILURE"
)<
  { id_user: number; meetingID: string },
  { id_user: number; meetingID: string },
  Error | string
>();

export const filterCalendars = createAsyncAction(
  "details/FILTER_CALENDAR_REQUEST",
  "details/FILTER_CALENDAR_SUCCESS",
  "details/FILTER_CALENDAR_FAILURE"
)<{ token: string; id_user: number }, { gbs: GBArray }, Error | string>();

export const hideCalendar = createAction("details/HIDE_CALENDAR")<number>();

export const showError = createAction("details/SHOW_ERROR")<string>();
export const leadStatusUpdate = createAction("details/LEAD_UPDATE")<{
  orderNumber: number;
}>();

export const addNewOrderNote = createAsyncAction(
  "details/ADD_NEW_ORDER_NOTE_REQUEST",
  "details/ADD_NEW_ORDER_NOTE_SUCCESS",
  "details/ADD_NEW_ORDER_NOTE_FAILURE"
)<{ note: Note; token: string; order_id: number }, Note, Error>();

export const editNewOrderNote = createAsyncAction(
  "details/EDIT_ORDER_NOTE_REQUEST",
  "details/EDIT_ORDER_NOTE_SUCCESS",
  "details/EDIT_ORDER_NOTE_FAILURE"
)<{ note: Note; id_note: number; token: string }, Note, Error>();

export const deleteNewOrderNote = createAsyncAction(
  "details/DELETE_ORDER_NOTE_REQUEST",
  "details/DELETE_ORDER_NOTE_SUCCESS",
  "details/DELETE_ORDER_NOTE_FAILURE"
)<{ id_note: number; token: string }, number, Error>();

export const editCustomer = createAsyncAction(
  "details/EDIT_CUSTOMER_REQUEST",
  "details/EDIT_CUSTOMER_SUCCESS",
  "details/EDIT_CUSTOMER_FAILURE"
)<{ customer: Customer }, Customer, Error>();

export const assingMounter = createAsyncAction(
  "details/ASSIGN_MOUNTER_REQUEST",
  "details/ASSIGN_MOUNTER_SUCCESS",
  "details/ASSIGN_MOUNTER_FAILURE"
)<
  { token: string; id_order: number; id_mounter: number; mounting_date?: Date },
  { id_order: number; mounting_date: string },
  Error
>();

export const getCustomerResponseOption = createAsyncAction(
  "details/GET_CUSTOMER_RESPONSE_REQUEST",
  "details/GET_CUSTOMER_RESPONSE_SUCCESS",
  "details/GET_CUSTOMER_RESPONSE_FAILURE"
)<{ token: string }, CustomerResponseOptions[], Error>();

export const setCustomerResponseOption = createAsyncAction(
  "details/SET_CUSTOMER_RESPONSE_REQUEST",
  "details/SET_CUSTOMER_RESPONSE_SUCCESS",
  "details/SET_CUSTOMER_RESPONSE_FAILURE"
)<
  {
    token: string;
    id_order: number;
    id_gardinbus_customer_response: number;
    order_note: string;
    alarm?: string;
  },
  boolean,
  Error
>();

export const getOfflineDetail = createAsyncAction(
  "details/GET_OFFLINE_DETAIL_REQUEST",
  "details/GET_OFFLINE_DETAIL_SUCCESS",
  "details/GET_OFFLINE_DETAIL_FAILURE"
)<void, DetailsAPIResponse, Error>();

export const filterGB =
  createAction("details/FILTER_GB")<{
    newGB: GBArray;
  }>();
