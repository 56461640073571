import React, { useEffect, useMemo, useState } from "react";
import { IonRow, IonCol, IonProgressBar, IonModal } from "@ionic/react";
import { warningOutline, albumsOutline, alertCircleOutline, calendarOutline, callOutline, hourglassOutline, megaphoneOutline, ribbonOutline, rocketOutline, trophyOutline } from "ionicons/icons";
import { CalendarComponent, NotificationCard } from "../../components/";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "typesafe-actions";
import { getMeetingsForTheDay, getNotifications, dismissNotification } from "./redux/actions";
import { initCalendars } from "../Calendars/redux/actions";
import { showNotification } from "../Notification/redux/actions";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { eventStyleGetter } from "../../helpers";
import { RouteComponentProps } from "react-router";
import { Color } from "../../models/";
import { shallowEqual } from "react-redux";
const getIcon = (iconName: string) => {
  switch (iconName) {
    case "albumsOutline":
      return albumsOutline;
    case "alertCircleOutline":
      return alertCircleOutline;
    case "calendarOutline":
      return calendarOutline;
    case "callOutline":
      return callOutline;
    case "hourglassOutline":
      return hourglassOutline;
    case "megaphoneOutline":
      return megaphoneOutline;
    case "ribbonOutline":
      return ribbonOutline;
    case "rocketOutline":
      return rocketOutline;
    case "trophyOutline":
      return trophyOutline;
    case "warningOutline":
      return warningOutline;
    default:
      return null;
    // Handle the case where the icon name does not match any case
  }
};
const allFinishedLoading = (loading: {
  dna: boolean;
  global_config: boolean;
  products: boolean;
  pricelist: boolean;
  attributes: boolean;
}) => {
  return Object.values(loading).every(value => value !== true);
};
const style: React.CSSProperties = {
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%"
};
const imageStyle: React.CSSProperties = {
  maxWidth: "30rem",
  marginTop: "2rem",
  marginBottom: "2rem"
};
const DashboardFeatureComponent: React.FC<RouteComponentProps> = props => {
  const {
    history
  } = props;
  const {
    t
  } = useTranslation();
  const dispatch = useDispatch();
  const userID = useSelector((state: RootState) => state.authReducer.userID);
  const role = useSelector((state: RootState) => state.authReducer.role);
  const token = useSelector((state: RootState) => state.authReducer.token);
  const calendarName = useSelector((state: RootState) => state.settingsReducer.calendarChoosen);
  const gbs = useSelector((state: RootState) => state.calendarsReducer.operator);
  const isLoadingCalendar = useSelector((state: RootState) => state.calendarsReducer.isLoading);
  const isLoadingDNA = useSelector((state: RootState) => state.authReducer.loadingRequests, shallowEqual);
  const notifications = useSelector((state: RootState) => state.dashboardReducer.notifications);
  const meetings = useSelector((state: RootState) => state.dashboardReducer.meetings);
  const drivingEvents = useSelector((state: RootState) => state.dashboardReducer.calendarDrivingEvents, shallowEqual);
  const dateStart = useSelector((state: RootState) => state.calendarsReducer.dateStart);
  const dateEnd = useSelector((state: RootState) => state.calendarsReducer.dateEnd);
  useEffect(() => {
    if (role && userID) {
      dispatch(getNotifications.request({
        token: token!
      }));
      dispatch(initCalendars.request({
        token: token!,
        userID: Number(userID),
        userRole: role,
        calendarName: calendarName ? calendarName : "UNIG",
        dateStart: new Date(),
        dateEnd
      }));
      dispatch(getMeetingsForTheDay.request({
        token: token!,
        gbID: userID!
      }));
    }
    // Only run this effect once on component mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, userID, token, calendarName, dateEnd, dispatch]);
  const finishedLoading = useMemo(() => {
    return allFinishedLoading(isLoadingDNA);
  }, [isLoadingDNA]);
  const currentlyLoadingString = useMemo(() => {
    const objs = Object.entries(isLoadingDNA).map(array => {
      if (array[1]) {
        return array[0];
      }
      return null;
    });
    return "Currently loading: " + objs.filter(obj => obj).join(", ");
  }, [isLoadingDNA]);
  const [ModalState, setModalState] = useState(!finishedLoading);
  useEffect(() => {
    if (finishedLoading) {
      setModalState(false);
    }
  }, [finishedLoading]);
  return <>
      <IonRow style={{
      width: "100%",
      display: "flex",
      overflow: "auto",
      flexWrap: "nowrap"
    }}>
        {notifications && notifications.map((d, i) => <IonCol size={"4"} key={i}>
              <NotificationCard id={d.id_gb_user_notification} title={d.text} link={d.action} color={(d.color as Color)} icon={getIcon(d.icon)} onDismiss={v => dispatch(dismissNotification.request({
          id_notification: v,
          token: token!
        }))} />
            </IonCol>)}
      </IonRow>
      {finishedLoading ? <CalendarComponent date={dateStart} isLoading={Boolean(isLoadingCalendar)} onNavigate={newDate => {
      const dateEnd = moment(newDate).endOf("week").toString();
      dispatch(initCalendars.request({
        token: token!,
        userID: userID!,
        userRole: role,
        calendarName: "UNIG",
        dateStart: newDate,
        dateEnd: dateEnd
      }));
    }} onSeeDetails={e => history.push(`/leads/${e.orderID}`)} popoverEnabled={false} defaultDate={moment().toDate()} style={{
      marginTop: "2rem"
    }} onError={message => dispatch(showNotification({
      message: message,
      color: "warning",
      type: "Toast"
    }))} gbs={gbs} timeslots={1} // number of slots within an hour
    onDeleteEvent={() => undefined} onSelectNewEvent={() => undefined} onEditEvent={() => undefined} onReschedule={() => {}} views={{
      agenda: true,
      day: true
    }} adminEvents={meetings} drivingEvents={drivingEvents} defaultView={"day"} eventPropGetter={(e, start, end) => eventStyleGetter(e, moment(start).toDate(), moment(end).toDate(), gbs)} /> : <>
          <IonModal isOpen={ModalState} onDidDismiss={() => setModalState(false)}>
            <IonProgressBar type="indeterminate" />
            <div style={style}>
              <div>
                <img style={imageStyle} src="assets/img/progress.svg" alt="loading..." />
                <h1>{t("DNAModalTitle")}</h1>
                <p>{t("DNAModalSubtitle")}</p>
                <p>{t("DNAModalSubtitle2")}</p>
                <p>
                  {t("Currently loading:")} {" " + currentlyLoadingString}
                </p>
                {/* <IonButton onClick={() => setModalState(false)}>{t("Close")}</IonButton> */}
              </div>
            </div>
          </IonModal>
          <IonProgressBar type="indeterminate" />
        </>}
    </>;
};
const DashboardFeature = React.memo(DashboardFeatureComponent);
export default DashboardFeature;