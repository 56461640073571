import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IonInput, IonItem, IonLabel, IonRefresher, IonRefresherContent, IonSearchbar } from "@ionic/react";
import { calendarOutline, checkmark, chevronDownCircleOutline } from "ionicons/icons";
import { AssignMounterForm, Modal, ResponsiveTable } from "../../components";
import { createHeaders, fullName } from "../../helpers";
import { BasicOrder, ModalAction, MountingPending, RowAction } from "../../models";
import { finishMounting, initMountings } from "./redux/actions";
import { RootState } from "typesafe-actions";
import moment from "moment";
import { RefresherEventDetail } from "@ionic/core";
import { RouteComponentProps } from "react-router";
import { assingMounter } from "../Details/redux/actions";
import { SaveMounterForm } from "../../components/AssignMounterForm/AssignMounterForm";
import { getMounters, openTabs, selectedFilters } from "../Leads/redux/actions";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import { flatten } from "lodash";
import PhotoUpload from "../S3/PhotoUpload";
const MountingsFeature: React.FC<{} & RouteComponentProps> = props => {
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.mountingsReducer.loading);
  const activeColumnFilters = useSelector((state: RootState) => state.leadsReducer.columnFilters);
  const activeTabs = useSelector((state: RootState) => state.leadsReducer.openTabs);
  const leads = useSelector((state: RootState) => state.mountingsReducer.mountingRows);
  const auth = useSelector((state: RootState) => state.authReducer);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [timeSpent, setTimeSpent] = useState<{
    order_id: number;
    open: boolean;
    order_note: string | undefined;
    mounting_time: string;
    canSave?: boolean;
  }>({
    order_id: 0,
    open: false,
    order_note: undefined,
    mounting_time: "",
    canSave: false
  });
  const handleUploadSuccess = () => {
    setTimeSpent({
      ...timeSpent,
      canSave: true
    });
  };
  const {
    t
  } = useTranslation();
  useEffect(() => {
    dispatch(initMountings.request(auth.token!));
    dispatch(getMounters.request({
      token: auth.token
    }));
  }, [auth.token, dispatch]);
  const renderTimeSpent = () => {
    return <Modal isOpen={timeSpent.open} onClose={() => setTimeSpent({
      open: false,
      order_id: undefined,
      mounting_time: "",
      order_note: undefined
    })} title={"Finished Mounting"} actions={[{
      name: "Cancel",
      fn: () => setTimeSpent({
        open: false,
        order_id: undefined,
        mounting_time: "",
        order_note: undefined
      }),
      type: "button"
    }, {
      name: "Save",
      disabled: !Boolean(timeSpent.canSave),
      fn: () => dispatch(finishMounting.request({
        token: auth.token!,
        mounting_time: timeSpent.mounting_time,
        order_id: timeSpent.order_id,
        order_note: timeSpent.order_note
      })),
      type: "button"
    }]}>
        <IonItem>
          <IonLabel position="stacked">{t("Mounting time")}</IonLabel>

          <IonInput name="mounting_time" type="time" placeholder={t("Mounting time")} value={timeSpent.mounting_time} onIonChange={e => setTimeSpent({
          ...timeSpent,
          mounting_time: e.detail.value!
        })} />
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">{t("Note")}</IonLabel>

          <IonInput name="order_note" type="text" placeholder={t("Note")} value={timeSpent.order_note ?? ""} onIonChange={e => setTimeSpent({
          ...timeSpent,
          order_note: e.detail.value!
        })} />
        </IonItem>
        <IonItem lines="none" style={{
        width: "100%"
      }}>
          <PhotoUpload order_id={timeSpent.order_id!} onUploadSuccess={handleUploadSuccess} />
        </IonItem>
      </Modal>;
  };
  const MountingActions: RowAction<MountingPending>[] = [{
    name: t("Finished"),
    fn: (e, r) => {
      setTimeSpent({
        order_id: Number(r.OrderID),
        open: true,
        mounting_time: "",
        order_note: undefined,
        canSave: false
      });
    },
    primary: true,
    icon: checkmark
  }];
  const mountingPending = leads.filter(r => Number(r.current_state_gardinbus) === 7 && r.gardinbus_mounting_date === "0000-00-00 00:00:00").map(lead => {
    const formattedLead: MountingPending = {
      notes: lead.notes,
      Note: lead.has_note,
      background_color: lead.background_color,
      OrderID: Number(lead.id_order),
      OrderDate: moment(lead.date_add).format("YYYY-MM-DD").toString(),
      CustomerName: fullName(lead.customer.firstname, lead.customer.lastname),
      CustomerPhone: isNaN(Number(lead.customer.phone)) ? (t("NotDefined") as string) : Number(lead.customer.phone),
      Mounter: lead.mounter ? fullName(lead.mounter.first_name!, lead.mounter.last_name!) : (t("None") as string),
      MountingDate: moment(lead.gardinbus_mounting_date).isValid() ? lead.gardinbus_mounting_date : (t("NotDefined") as string),
      ExpectedDeliveryDate: moment(lead.gardinbus_measuring_date).isValid() ? lead.gardinbus_measuring_date! : (t("NotDefined") as string)
    };
    return formattedLead;
  });
  const overdueMounts = leads.filter(r => Number(r.current_state_gardinbus) === 7 && new Date(r.gardinbus_mounting_date) < new Date()).map(lead => {
    const formattedLead: MountingPending = {
      notes: lead.notes,
      Note: lead.has_note,
      background_color: lead.background_color,
      OrderID: Number(lead.id_order),
      OrderDate: moment(lead.date_add).format("YYYY-MM-DD").toString(),
      CustomerName: fullName(lead.customer.firstname, lead.customer.lastname),
      CustomerPhone: isNaN(Number(lead.customer.phone)) ? (t("NotDefined") as string) : Number(lead.customer.phone),
      Mounter: lead.mounter ? fullName(lead.mounter.first_name!, lead.mounter.last_name!) : (t("None") as string),
      MountingDate: moment(lead.gardinbus_mounting_date).isValid() ? lead.gardinbus_mounting_date : (t("NotDefined") as string),
      ExpectedDeliveryDate: moment(lead.gardinbus_measuring_date).isValid() ? lead.gardinbus_measuring_date! : (t("NotDefined") as string)
    };
    return formattedLead;
  });
  const logHour = leads.filter(r => Number(r.current_state_gardinbus) === 7 && moment(r.gardinbus_mounting_date).isValid() && moment(r.gardinbus_mounting_date).toDate() >= new Date(Date.now())).map(lead => {
    const formattedLead: MountingPending = {
      background_color: lead.background_color,
      notes: lead.notes,
      Note: lead.has_note,
      OrderID: Number(lead.id_order),
      OrderDate: moment(lead.date_add).format("YYYY-MM-DD").toString(),
      CustomerName: fullName(lead.customer.firstname, lead.customer.lastname),
      CustomerPhone: isNaN(Number(lead.customer.phone)) ? (t("NotDefined") as string) : Number(lead.customer.phone),
      Mounter: lead.mounter ? fullName(lead.mounter.first_name!, lead.mounter.last_name!) : (t("None") as string),
      MountingDate: moment(lead.gardinbus_mounting_date).isValid() ? lead.gardinbus_mounting_date : (t("NotDefined") as string),
      ExpectedDeliveryDate: moment(lead.gardinbus_measuring_date).isValid() ? lead.gardinbus_measuring_date! : (t("NotDefined") as string)
    };
    return formattedLead;
  });
  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    dispatch(initMountings.request(auth.token!));
    !loading && event.detail.complete();
  };
  const filterArray = <T extends BasicOrder[],>(array: T) => {
    const filteredArray = searchText ? (array.filter(order => order.OrderID.toString().includes(searchText) || order.CustomerName.toString().toLowerCase().includes(searchText.toLowerCase())) as T) : array;
    return filteredArray;
  };
  const filterLeads = (text: string) => {
    setSearchText(text);
  };
  const [MounterModal, setMounterModal] = useState(false);
  const [RowID, setRowID] = useState(0);
  const AssignMounter: RowAction<MountingPending>[] = [{
    name: t("Assign"),
    fn: (e, r) => {
      setMounterModal(true);
      setRowID(r.OrderID);
    },
    primary: true,
    icon: calendarOutline
  }, {
    name: t("Reschedule"),
    fn: (e, r) => {}
  }];
  const mounterRef = createRef<SaveMounterForm>();
  const mounters = useSelector((state: RootState) => state.leadsReducer.mounters);
  const assignButton: ModalAction = {
    name: t("Assign"),
    fn: () => {
      dispatch(getMounters.request({
        token: auth.token!
      }));
      dispatch(assingMounter.request({
        token: auth.token!,
        id_mounter: mounterRef.current!.onSave().assignedMounter.id,
        id_order: RowID,
        mounting_date: mounterRef.current!.onSave().assignedMounter.startDate
      }));
      setMounterModal(false);
      setRowID(0);
    },
    type: "button"
  };
  const renderAssignMounterModal = () => {
    return <Modal title={t("AssignMounting")} isOpen={MounterModal} onClose={() => {
      setMounterModal(false);
    }} actions={[assignButton]}>
        <AssignMounterForm ref={mounterRef} mounters={mounters && auth.role !== "Mounter" ? mounters : auth.role === "Mounter" ? [{
        id_gb_user: auth.userID!,
        id_gb_user_role: 2,
        id_gb_user_team: 0,
        id_shop: 0,
        name: auth.name!,
        lastname: "",
        email: "testing@uniggardin.dk"
      }] : []} onError={e => console.log(e)} />
      </Modal>;
  };
  return <>
      {timeSpent && renderTimeSpent()}

      <IonSearchbar value={searchText ? searchText : ""} onIonChange={e => filterLeads(e.detail.value!)} placeholder={t("SearchBy")} />
      <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
        <IonRefresherContent pullingIcon={chevronDownCircleOutline} pullingText={t("PullToRefresh")} refreshingSpinner="circles" refreshingText={t("Refreshing")}></IonRefresherContent>
      </IonRefresher>
      {loading ? <Loading loading={loading} onGoBack={() => props.history.goBack()} /> : <>
          {renderAssignMounterModal()}
          {leads.length > 0 ? <>
              <ResponsiveTable onSetColumnFilters={keys => {
          const clone = activeColumnFilters;
          const object = clone.findIndex(c => c.rowName === t("OverdueMounting"));
          if (object !== -1) {
            clone[object].filters = keys;
          } else {
            clone.push({
              rowName: t("OverdueMounting"),
              filters: keys
            });
          }
          dispatch(selectedFilters(clone));
        }} columnFilters={flatten(activeColumnFilters.filter(f => f.rowName === t("OverdueMounting")).map(f => f.filters))} collapse={!!!activeTabs.find(tab => tab === t("OverdueMounting"))} onCollapse={tableName => {
          if (activeTabs.includes(tableName)) {
            return dispatch(openTabs(activeTabs.filter(tab => tab !== tableName)));
          }
          return dispatch(openTabs([...activeTabs, tableName]));
        }} tableName={t("OverdueMounting")} hideColumnByKeys={["status", "background_color", "notes"]} rowActions={AssignMounter} columns={createHeaders(overdueMounts)} rows={filterArray<any[]>(overdueMounts)} stripped onClickRow={r => {
          props.history.push(`/mountings/${r.OrderID}`);
        }} onClickShowNotes={r => console.log("row", r)} />
              <ResponsiveTable onSetColumnFilters={keys => {
          const clone = activeColumnFilters;
          const object = clone.findIndex(c => c.rowName === t("MountingsPending"));
          if (object !== -1) {
            clone[object].filters = keys;
          } else {
            clone.push({
              rowName: t("MountingsPending"),
              filters: keys
            });
          }
          dispatch(selectedFilters(clone));
        }} columnFilters={flatten(activeColumnFilters.filter(f => f.rowName === t("MountingsPending")).map(f => f.filters))} collapse={!!!activeTabs.find(tab => tab === t("MountingsPending"))} onCollapse={tableName => {
          if (activeTabs.includes(tableName)) {
            return dispatch(openTabs(activeTabs.filter(tab => tab !== tableName)));
          }
          return dispatch(openTabs([...activeTabs, tableName]));
        }} tableName={t("MountingsPending")} hideColumnByKeys={["status", "background_color", "notes"]} rowActions={AssignMounter} columns={createHeaders(mountingPending)} rows={filterArray<MountingPending[]>(mountingPending)} stripped onClickRow={r => {
          props.history.push(`/mountings/${r.OrderID}`);
        }} onClickShowNotes={r => console.log("row", r)} />
              <ResponsiveTable onSetColumnFilters={keys => {
          const clone = activeColumnFilters;
          const object = clone.findIndex(c => c.rowName === t("ScheduledMountings"));
          if (object !== -1) {
            clone[object].filters = keys;
          } else {
            clone.push({
              rowName: t("ScheduledMountings"),
              filters: keys
            });
          }
          dispatch(selectedFilters(clone));
        }} columnFilters={flatten(activeColumnFilters.filter(f => f.rowName === t("ScheduledMountings")).map(f => f.filters))} collapse={!!!activeTabs.find(tab => tab === t("ScheduledMountings"))} onCollapse={tableName => {
          if (activeTabs.includes(tableName)) {
            return dispatch(openTabs(activeTabs.filter(tab => tab !== tableName)));
          }
          return dispatch(openTabs([...activeTabs, tableName]));
        }} tableName={t("ScheduledMountings")} hideColumnByKeys={["status", "background_color", "notes"]} rowActions={MountingActions} columns={createHeaders(logHour)} rows={filterArray<MountingPending[]>(logHour)} stripped onClickRow={r => {
          props.history.push(`/mountings/${r.OrderID}`);
        }} onClickShowNotes={r => console.log("row", r)} />
            </> : <h3 style={{
        padding: "2rem"
      }}>{t("NoMountings")}</h3>}
        </>}
    </>;
};
export default MountingsFeature;