import React, { useState } from "react";
import { IonButton, IonItem, IonLabel, IonList, IonListHeader, IonText } from "@ionic/react";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
const accessKeyId = process.env.REACT_APP_ACCESS_KEY_ID!;
const secretAccessKey = process.env.REACT_APP_SECRET_ACCESS_KEY!;

// Configure S3 client
const s3Client = new S3Client({
  region: "eu-north-1",
  credentials: {
    accessKeyId,
    secretAccessKey
  }
});
interface Props {
  order_id: string | number;
  onUploadSuccess: () => void; // Callback to notify the parent component about the successful upload
}
const PhotoUpload: React.FC<Props> = ({
  order_id,
  onUploadSuccess
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFiles(Array.from(event.target.files));
    }
  };
  const handleUpload = async () => {
    setUploading(true);
    setUploadSuccess(false);
    try {
      for (let file of selectedFiles) {
        const command = new PutObjectCommand({
          Bucket: "gardinbus-app",
          Key: `mounting/${order_id}/${file.name}`,
          Body: file,
          ContentType: file.type,
          ACL: "public-read"
        });
        const response = await s3Client.send(command);
      }
      setUploadSuccess(true);
      onUploadSuccess(); // Call the callback after a successful upload
    } catch (error) {
      console.error("Upload failed:", error);
      setUploadSuccess(false);
    } finally {
      setUploading(false);
    }
  };
  const handleTakePhoto = async () => {
    try {
      const photo = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        quality: 90,
        allowEditing: true,
        source: CameraSource.Camera
      });
      const response = await fetch(photo.webPath!);
      const blob = await response.blob();
      const file = new File([blob], "photo.jpg" + selectedFiles.length, {
        type: "image/jpeg"
      });
      setSelectedFiles(prevFiles => [...prevFiles, file]);
    } catch (error) {
      console.error("Photo capture failed:", error);
    }
  };
  return <IonItem lines="none" style={{
    width: "100%"
  }}>
      <div style={{
      width: "100%"
    }}>
        <IonLabel position="stacked">Upload Photos</IonLabel>
        <div style={{
        border: "2px dashed #ccc",
        padding: "20px",
        textAlign: "center",
        marginTop: "10px",
        marginBottom: "10px",
        borderRadius: "10px",
        cursor: "pointer"
      }}>
          <input type="file" multiple onChange={handleFileChange} style={{
          display: "none"
        }} id="file-upload" />
          <label htmlFor="file-upload" style={{
          cursor: "pointer"
        }}>
            Click to select files
          </label>
        </div>
        {selectedFiles.length > 0 && <IonList>
            <IonListHeader>
              <IonLabel>Selected Files</IonLabel>
            </IonListHeader>
            {selectedFiles.map((file, index) => <IonItem key={index}>
                <IonLabel>{file.name}</IonLabel>
              </IonItem>)}
          </IonList>}
        <IonButton expand="block" onClick={handleTakePhoto}>
          Take Photo
        </IonButton>
        <IonButton expand="block" onClick={handleUpload} style={{
        marginTop: "10px"
      }} disabled={uploading || selectedFiles.length === 0}>
          {uploading ? "Uploading..." : "Upload"}
        </IonButton>
        {uploadSuccess && <IonText color="success">Upload successful!</IonText>}
        {!uploading && !uploadSuccess && selectedFiles.length > 0 && <IonText color="danger">
            Click 'Upload' to upload the selected files
          </IonText>}
      </div>
    </IonItem>;
};
export default PhotoUpload;