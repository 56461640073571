import moment from "moment";
import { Observable, of, from } from "rxjs";
import { ajax } from "rxjs/ajax";
import { catchError, map, flatMap } from "rxjs/operators";
import { showNotification } from "../features/Notification/redux/actions";
import { Meeting } from "../models";
import {
  API_CUSTOMER_REPONSE_OPTIONS,
  API_DETAILS,
  API_GB_CALENDAR,
  API_LEADS_PREVIEW,
  API_USER,
} from "../models/apiModels";
import { AppVersion } from "@awesome-cordova-plugins/app-version";
import { Capacitor } from "@capacitor/core";

export interface Mounter_API {
  id_gb_user: number;
  id_shop: number;
  id_gb_user_role: number;
  id_gb_user_team: number;
  name: string;
  lastname: string;
  email: string;
}
const API_URL = process.env.REACT_APP_API_URL;

export const apiVersionWrapper = (
  url: string,
  method: string,
  headers?: object,
  body?: object | string
) => {
  const request = {
    url,
    method,
    headers,
    body,
  };
  if (Capacitor.getPlatform() === "ios") {
    return from(AppVersion.getVersionNumber()).pipe(
      flatMap((versionNumber) =>
        from(AppVersion.getVersionCode()).pipe(
          flatMap((val) => {
            return ajax({
              ...request,
              headers: {
                ...headers,
                "app-version": versionNumber + "(" + val + ")",
              },
            });
          })
        )
      )
    );
  }
  return ajax({
    ...request,
    headers: {
      ...headers,
      "app-version": "web",
    },
  });
};

export const ApiGetToken = (email: string, password: string) =>
  apiVersionWrapper(API_URL + "requestToken", "POST", undefined, {
    email,
    password,
  }).pipe(
    map((response) => {
      const user: API_USER = response.response;
      return user;
    })
  );

//TODO duplicate
export const ApiGetLeads = (token: string, force?: boolean) =>
  apiVersionWrapper(
    `${API_URL}getLeads/preview${force ? "/force" : ""}`,
    "GET",
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }
  ).pipe(
    map((response) => {
      const leads: API_LEADS_PREVIEW[] = response.response;
      return leads;
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );

export const ApiGetLeadsFromTimestamp = (
  token: string,
  date: Date,
  force?: boolean
) =>
  apiVersionWrapper(
    `${API_URL}getLeads/preview/${moment(date).format("YYYY-MM-DD HH:mm:ss")}${
      force ? "/force" : ""
    }`,
    "GET",
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }
  ).pipe(
    map((response) => {
      const leads: API_LEADS_PREVIEW[] = response.response;
      return leads;
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );

export const ApiGetCustomerResponseOptions = (
  token: string
): API_CUSTOMER_REPONSE_OPTIONS[] | Observable<Error> =>
  apiVersionWrapper(API_URL + "getCustomerResponseOptions", "GET", {
    Authorization: `Bearer ${token}`,
  }).pipe(
    map((response) => {
      const customerResponseOptions: API_CUSTOMER_REPONSE_OPTIONS =
        response.response;
      return customerResponseOptions;
    }),
    catchError((error) => {
      return of(error);
    })
  );

export const ApiAssignFitter = (
  order_id: number,
  token: string,
  id_fitter: number,
  gardinbus_measuring_date: string,
  gardinbus_measuring_date_end: string,
  notify?: boolean
): Boolean | Observable<Error> =>
  apiVersionWrapper(
    API_URL + `assignFitter/${order_id}`,
    "PUT",
    {
      Authorization: `Bearer ${token}`,
    },
    {
      id_fitter,
      gardinbus_measuring_date,
      gardinbus_measuring_date_end,
      notify,
    }
  ).pipe(
    map((response) => {
      const done: Boolean = response.response;
      return done;
    }),
    catchError((error) => {
      return of(error);
    })
  );

export const ApiSetCustomerResponse = (
  order_id: number,
  token: string,
  id_gardinbus_customer_response: string,
  order_note?: string,
  alarm?: string
): Boolean | Observable<Error> =>
  apiVersionWrapper(
    API_URL + `setCustomerResponse/${order_id}`,
    "PUT",
    {
      Authorization: `Bearer ${token}`,
    },
    {
      id_gardinbus_customer_response,
      order_note,
      alarm,
    }
  ).pipe(
    map((response) => {
      const done: Boolean = response.response;
      return done;
    }),
    catchError((error) => {
      return of(error);
    })
  );

export const ApiRescheduleMeasuring = (
  order_id: number,
  token: string,
  id_fitter: number,
  gardinbus_measuring_date: string,
  gardinbus_measuring_date_end: string,

  notify?: boolean,
  order_note?: string
): Boolean | Observable<Error> =>
  apiVersionWrapper(
    API_URL + `rescheduleMeasuring/${order_id}`,
    "PUT",
    {
      Authorization: `Bearer ${token}`,
    },
    {
      id_fitter,
      gardinbus_measuring_date,
      gardinbus_measuring_date_end,
      notify,
      order_note,
    }
  ).pipe(
    map((response) => {
      const done: Boolean = response.response;
      return done;
    }),
    catchError((error) => {
      return of(error);
    })
  );

export const ApiResendOffer = (
  order_id: number,
  token: string
): Boolean | Observable<Error> =>
  apiVersionWrapper(API_URL + `resendOffer/${order_id}`, "GET", {
    Authorization: `Bearer ${token}`,
  }).pipe(
    map((response) => {
      const done: Boolean = response.response;
      return done;
    }),
    catchError((error) => {
      return of(error);
    })
  );

export const ApiAssignMounter = (
  order_id: number,
  token: string,
  id_mounter: string,
  gardinbus_mounting_date: string
): Boolean | Observable<Error> =>
  apiVersionWrapper(
    API_URL + `assignMounter/${order_id}`,
    "PUT",
    {
      Authorization: `Bearer ${token}`,
    },
    {
      id_mounter,
      gardinbus_mounting_date,
    }
  ).pipe(
    map((response) => {
      const done: Boolean = response.response;
      return done;
    }),
    catchError((error) => {
      return of(error);
    })
  );
export const ApiFinishMounting = (
  order_id: number,
  token: string,
  mounting_time: string,
  order_note?: string
): Boolean | Observable<Error> =>
  apiVersionWrapper(
    API_URL + `finishMounting/${order_id}`,
    "PUT",
    {
      Authorization: `Bearer ${token}`,
    },
    {
      mounting_time,
      order_note,
    }
  ).pipe(
    map((response) => {
      const done: Boolean = response.response;
      return done;
    }),
    catchError((error) => {
      return of(error);
    })
  );

export const ApiGetOrderDetails = (order_id: number, token: string) =>
  apiVersionWrapper(API_URL + `getOrderDetails/${order_id}`, "GET", {
    Authorization: `Bearer ${token}`,
  }).pipe(
    map((response) => {
      const details: API_DETAILS = response.response;
      return details;
    }),
    catchError((error) => {
      return of(error);
    })
  );

export const getAssignedGBMeetings = (
  token: string,
  idGB: number,
  startingDate: Date | string
) => {
  const parsedDate = moment(startingDate).format("YYYY-MM-DD");
  const API_URL = `${process.env.REACT_APP_API_URL}getCalendar/${idGB}/${parsedDate}`;
  let meetings: Meeting[] = [];
  return apiVersionWrapper(API_URL, "GET", {
    Authorization: `Bearer ${token}`,
  }).pipe(
    map((response) => {
      const gbCalendar: API_GB_CALENDAR[] = response.response;
      gbCalendar.forEach((calendar) => {
        const meeting: Meeting = {
          notes: calendar.notes,
          meetingID: calendar.id_gb_calendar,
          orderID: calendar.id_order,
          start: calendar.date_start
            ? moment(calendar.date_start).toDate()
            : undefined,
          end: calendar.date_end
            ? moment(calendar.date_end).toDate()
            : undefined,
          title: `${calendar.description}`,
          // calendar.id_order
          //   ? `${calendar.address.postcode} ORDER: ${
          //       calendar.id_order
          //     } - ${fullName(
          //       calendar.customer.firstname,
          //       calendar.customer.lastname
          //     )}`
          //   : `${calendar.description}`,
          operatorID: calendar.id_gb_user,
          notify: false,
          map: calendar.address.map,
        };
        meetings.push(meeting);
      });
      return meetings;
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );
  //
  // return EMPTY;
};

export const getAllMeetings = (token: string, startingDate: Date | string) => {
  const parsedDate = moment(startingDate).format("YYYY-MM-DD");
  const API_URL = `${process.env.REACT_APP_API_URL}getAllCalendars/${parsedDate}/force`;
  let meetings: Meeting[] = [];
  return apiVersionWrapper(API_URL, "GET", {
    Authorization: `Bearer ${token}`,
  }).pipe(
    map((response) => {
      const gbCalendar: API_GB_CALENDAR[] = response.response;
      gbCalendar.forEach((calendar) => {
        const meeting: Meeting = {
          notes: calendar.notes,
          meetingID: calendar.id_gb_calendar,
          orderID: calendar.id_order,
          start: calendar.date_start
            ? moment(calendar.date_start).toDate()
            : undefined,
          end: calendar.date_end
            ? moment(calendar.date_end).toDate()
            : undefined,
          title: `${calendar.description}`,
          // calendar.id_order
          //   ? `${
          //       calendar.address.postcode
          //         ? calendar.address.postcode + "/"
          //         : "NoPostcode/"
          //     }${calendar.id_order} - ${fullName(
          //       calendar.customer.firstname,
          //       calendar.customer.lastname
          //     )}`
          //   : `${calendar.description}`,
          operatorID: calendar.id_gb_user,
          notify: false,
          map: calendar.address.map,
        };
        meetings.push(meeting);
      });
      return meetings;
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );
  //
  // return EMPTY;
};

export const getMountersAPI = (token: string) => {
  const API_URL = `${process.env.REACT_APP_API_URL}getUsers/2`;
  return apiVersionWrapper(API_URL, "GET", {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  }).pipe(
    map((response) => {
      const mounter: Mounter_API[] = response.response;
      return mounter;
    }),
    catchError((error: Error) => {
      return of(
        showNotification({
          color: "danger",
          message: error.message,
          type: "Toast",
        })
      );
    })
  );
};

export interface DNA_API {
  [index: string]: {
    [index: string]: {
      subconditions: {
        [index: string]: {
          type: string;
          operator: string;
          value: number;
        };
      };
      to_hide: {
        id_attribute: number[];
        id_attribute_group: number[];
      };
    };
  };
}
export const getDNAAPI = (token: string) => {
  const API_URL = `${process.env.REACT_APP_API_URL}getDNA`;
  return apiVersionWrapper(API_URL, "GET", {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  }).pipe(
    map((response) => {
      const dna: DNA_API = response.response;
      return dna;
    }),
    catchError((error: Error) => {
      return of(
        showNotification({
          color: "danger",
          message: error.message,
          type: "Toast",
        })
      );
    })
  );
};

export interface Products_API {
  products: {
    id_product: number;
    name: string;
    product_type: string;
    price: number;
    mounting_price: number;
    tax: number;
    inputs: string[] | null;
    limits: {
      min_width: number | null;
      max_width: number | null;
      min_height: number | null;
      max_height: number | null;
      max_width2: number | null;
      max_height2: number | null;
      min_height2: number | null;
    } | null;
  }[];
}

export const getProducts = (token: string) => {
  const API_URL = `${process.env.REACT_APP_API_URL}getProducts`;
  return apiVersionWrapper(API_URL, "GET", {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  }).pipe(
    map((response) => {
      const products: Products_API = response.response;
      return products;
    }),
    catchError((error: Error) => {
      return of(
        showNotification({
          color: "danger",
          message: error.message,
          type: "Toast",
        })
      );
    })
  );
};
export interface PriceList_API {
  [index: string]: {
    id_product: number;
    width_from: number;
    width_to: number;
    height_from: number;
    height_to: number;
    price: number;
    conversion_rate_operator: string;
    conversion_rate: number;
    calculated_price: number;
  }[];
}

export const getPriceList = (token: string) => {
  const API_URL = `${process.env.REACT_APP_API_URL}getPricelist`;
  return apiVersionWrapper(API_URL, "GET", {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  }).pipe(
    map((response) => {
      const pricelist: PriceList_API = response.response;
      return pricelist;
    }),
    catchError((error: Error) => {
      return of(
        showNotification({
          color: "danger",
          message: error.message,
          type: "Toast",
        })
      );
    })
  );
};

export interface Attributes_API {
  [index: string]: {
    [index: string]: {
      id_attribute_group: number;
      group_public_name: string | null;
      group_position: number;
      id_shape: number;
      id_bracket: string | null;
      quantity_enabled: number;
      is_color_group: number;
      hidden: number;
      is_dynamic: number;
      multiselectable_group: number;
      optional: number;
      attributes: {
        [index: string]: {
          id_attribute: number;
          id_product_attribute: number;
          public_name: string | null;
          position: number;
          price_impact: string;
          price_impact_value: number;
          price_impact_value_old: number;
          conversion_rate_attribute: number;
          mounting_price: number;
          is_default: number;
        }[];
      };
    };
  };
}

export const getAttributes = (token: string) => {
  const API_URL = `${process.env.REACT_APP_API_URL}getAttributes`;
  return apiVersionWrapper(API_URL, "GET", {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  }).pipe(
    map((response) => {
      const attributes: Attributes_API = response.response;
      return attributes;
    }),
    catchError((error: Error) => {
      return of(
        showNotification({
          color: "danger",
          message: error.message,
          type: "Toast",
        })
      );
    })
  );
};

export interface Configuration_API {
  measuring_price: number;
  measuring_price_threshold: number;
  mounting_price: number;
  mounting_price_extra_width: number;
  mounting_price_extra_meter: number;
  shipping_price: number;
  shipping_price_threshold: number;
  shipping_price_long_goods: number;
  shipping_long_goods: number;
}
export const getConfiguration = (token: string) => {
  const API_URL = `${process.env.REACT_APP_API_URL}getConfiguration`;
  return apiVersionWrapper(API_URL, "GET", {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  }).pipe(
    map((response) => {
      const config: Configuration_API = response.response;
      return config;
    }),
    catchError((error: Error) => {
      return of(
        showNotification({
          color: "danger",
          message: error.message,
          type: "Toast",
        })
      );
    })
  );
};
