import { useState as _useState$ } from "react";
import { renderReactScope as _renderReactScope$ } from "million/react";
import { block as _block$ } from "million/react";
import React, { useEffect, useState } from "react";
interface Props {
  loading: boolean;
  onGoBack: () => void;
  timeout?: number;
}
const M$2 = ({
  onGoBack,
  loading: initialLoading,
  timeout
}) => {
  const [loading, setLoading] = useState(initialLoading);
  const [tooLongLoading, setTooLongLoading] = useState(false);
  useEffect(() => {
    let mounted = true;
    const timer = setTimeout(() => {
      if (mounted) setTooLongLoading(true);
    }, timeout ?? 20000);
    return () => {
      mounted = false;
      clearTimeout(timer);
    };
  }, [timeout]);
  useEffect(() => {
    setLoading(initialLoading);
  }, [initialLoading]);
  const overlayStyle: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    // Black transparent background
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000
  };
  const spinnerStyle: React.CSSProperties = {
    width: "50px",
    height: "50px",
    border: "8px solid rgba(0, 0, 0, 0.1)",
    borderTopColor: "#3498db",
    // Blue color for the top border
    borderRadius: "50%",
    animation: "spin 1s infinite linear"
  };
  const toastStyle: React.CSSProperties = {
    position: "fixed",
    bottom: "20px",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "#ffc107",
    padding: "10px 20px",
    borderRadius: "5px",
    display: tooLongLoading ? "block" : "none",
    zIndex: 1001
  };
  const _portal = _useState$(() => ({
      $: new Array(1)
    }))[0],
    _ = _renderReactScope$(loading && <div style={overlayStyle}>
          <div style={spinnerStyle}></div>
        </div>, false, _portal.$, 0, false);
  return <><P$ _={_} toastStyle={toastStyle} onGoBack={onGoBack} />{_portal.$.map(p => p.portal)}</>;
};
const P$ = /*million:transform*/_block$(({
  _,
  toastStyle,
  onGoBack
}) => {
  return <slot><style>
        {"\n          @keyframes spin {\n            to {\n              transform: rotate(360deg);\n            }\n          }\n        "}
      </style>{_}<div style={toastStyle}>
        Still working on it...{" "}
        <button onClick={onGoBack} style={{
        marginLeft: "10px"
      }}>
          Go back
        </button>
      </div></slot>;
}, {
  svg: false,
  shouldUpdate: (a, b) => a?._ !== b?._ || a?.toastStyle !== b?.toastStyle || a?.onGoBack !== b?.onGoBack
});
const Loading: React.FC<Props> = M$2;
export default Loading;