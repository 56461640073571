import { ActionType, getType, Reducer } from "typesafe-actions";

import * as calendars from "./actions";
import { GBArray, Meeting } from "../../../models/";
import { cloneDeep } from "lodash";
import moment from "moment";
import { CalendarEvent } from "../../../components/Calendar/Calendar";

export type CalendarsAction = ActionType<typeof calendars>;

interface CalendarsState {
  operator: GBArray;
  error?: string;
  isLoading?: boolean;
  isLoadingDrivingTime?: boolean;
  dateStart: Date | string;
  dateEnd: Date | string;
  suggestedMeetings: CalendarEvent[];
  allEvents: any[];
}

// function getMonday(d: Date) {
//   d = new Date(d);
//   var day = d.getDay(),
//     diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
//   return new Date(d.setDate(diff));
// }

const today = moment();
const from_date = today.startOf("week").toDate();
const to_date = today.endOf("week").toDate();

const initialState: CalendarsState = {
  operator: {},
  error: "",
  isLoading: true,
  isLoadingDrivingTime: false,
  dateStart: from_date,
  dateEnd: to_date,
  suggestedMeetings: [],
  allEvents: [],
};

export const calendarsReducer: Reducer<CalendarsState, CalendarsAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(calendars.clearFilterCalendars):
      const stateOperator = state.operator;
      Object.values(state.operator).forEach((o) => {
        stateOperator[o.id].isSelected = false;
      });
      return {
        ...state,
        operator: stateOperator,
      };
    case getType(calendars.clearSuggestions):
      return {
        ...state,
        suggestedMeetings: [],
      };
    case getType(calendars.getSuggestions.success):
      const suggestedMeetings = action.payload.map((payload) => {
        return {
          start: moment(payload.date_start).toDate(),
          end: moment(payload.date_end).toDate(),
          id: payload.id_fitter,
          title: "Suggestion #" + payload.position,
          suggestedMeeting: true,
        };
      });
      return {
        ...state,
        suggestedMeetings: suggestedMeetings,
      };
    case getType(calendars.checkDrivingTime.request):
      return {
        ...state,
        isLoadingDrivingTime: true,
      };
    case getType(calendars.checkDrivingTime.success):
      return {
        ...state,
        isLoadingDrivingTime: false,
      };
    case getType(calendars.checkDrivingTime.failure):
      return {
        ...state,
        isLoadingDrivingTime: false,
      };
    case getType(calendars.initCalendars.request):
      return {
        ...state,
        isLoading: true,
      };
    case getType(calendars.initCalendars.success):
      Object.values(action.payload.response).forEach((gb) => {
        if (state.operator[gb.id]) {
          action.payload.response[gb.id].isSelected =
            state.operator[gb.id].isSelected;
        } else if (Object.values(action.payload.response).length === 1) {
          action.payload.response[gb.id].isSelected = true;
        }
      });
      return {
        ...state,
        operator: action.payload.response,
        dateStart: action.payload.dateStart,
        dateEnd: action.payload.dateEnd,
      };
    case getType(calendars.addNewEvent.success):
      const withNewEventGBs: GBArray = cloneDeep(state.operator);
      const gbWithNewEvent = withNewEventGBs[action.payload.operatorId];

      const newEvent: Meeting = {
        ...action.payload.event,
        title:
          // action.payload.event.orderID
          //   ? `${
          //       action.payload.postcode
          //         ? action.payload.postcode + "/"
          //         : "NoPostcode/"
          //     }${action.payload.event.orderID} - ${action.payload.fullName}`
          //   :
          `${action.payload.event.title}`,
        operatorID: action.payload.operatorId,
      };
      gbWithNewEvent.meetings.push(newEvent);
      // Save to local storage
      // updatePouch();
      return {
        ...state,
        isLoading: false,
        operator: withNewEventGBs,
      };
    case getType(calendars.initCalendars.failure):
      return { ...state, isLoading: false, error: String(action.payload) };
    case getType(calendars.editEvent.success):
      const editedEventGBs: GBArray = { ...state.operator };
      const gbWithEditedEvent = editedEventGBs[action.payload.event.operatorID];
      //TODO
      // const newID = "_" + Math.random().toString(36).substr(2, 9);
      const editedEvent: Meeting = {
        ...action.payload.event,
        start: action.payload.newStartDate,
        end: action.payload.newEndDate,
      };
      //update gb meeting with edited event
      gbWithEditedEvent.meetings = gbWithEditedEvent.meetings.map((meeting) => {
        if (meeting.meetingID === action.payload.event.meetingID) {
          return editedEvent;
        }
        return meeting;
      });
      editedEventGBs[action.payload.event.operatorID] = gbWithEditedEvent;
      // gbWithEditedEvent.meetings.filter(
      //   (m) => m.meetingID !== action.payload.event.meetingID
      // );
      // gbWithEditedEvent.meetings.push(editedEvent);
      // gbWithEditedEvent.meetings = gbWithEditedEvent.meetings.filter(
      //   (m) => m.meetingID !== action.payload.event.meetingID
      // );
      // if (action.payload.event.orderID) {
      //   //If its related to an order
      //   objectToSave().then((r) => {
      //     if (r as OrderDetail[]) {
      //       const orders = r as OrderDetail[];
      //       orders.find(
      //         (order) => order.order_id === Number(action.payload.event.orderID)
      //       )!.meeting = editedEvent;
      //       storageSet<OrderDetail[]>("ordersDetails", orders!);
      //     }
      //   });
      // }
      // storageSet<GBArray>("gbs", editedEventGBs);
      return {
        ...state,
        isLoading: false,
        operator: editedEventGBs,
      };
    case getType(calendars.filterCalendars):
      const filteredGBs: GBArray = cloneDeep(state.operator);
      const index = action.payload.toString();
      if (filteredGBs[index]) {
        filteredGBs[index].isSelected = !filteredGBs[index].isSelected;
      }
      return {
        ...state,
        isLoading: false,
        operator: filteredGBs,
      };
    case getType(calendars.deleteEvent.success):
      const meetingID = action.payload;
      const operatorCopy = cloneDeep(state.operator);
      Object.values(operatorCopy).forEach((operator) => {
        operator.meetings = operator.meetings.filter(
          (m) => m.meetingID !== meetingID
        );
      });

      return {
        ...state,
        isLoading: false,
        operator: operatorCopy,
      };
    case getType(calendars.getEvents.request):
      return {
        ...state,
        isLoading: true,
      };
    case getType(calendars.getEvents.success):
      const eventsPayload = action.payload;
      const updatedGB = state.operator;
      eventsPayload.forEach((event) => {
        if (updatedGB && updatedGB[event.operatorID]) {
          if (
            !updatedGB[event.operatorID].meetings.find(
              (m) => m.meetingID === event.meetingID
            )
          ) {
            updatedGB[event.operatorID].meetings.push(event);
          }
          // updatedGB[event.operatorID].isSelected = true;
        }
      });
      return {
        ...state,
        operator: updatedGB,
        isLoading: false,
        allEvents: action.payload,
      };
    default:
      return state;
  }
};
